import React from "react"
import { PublicHeaderStyles } from "../utils/styles"
import logo from "../assets/img/logo.png"

let PHS = PublicHeaderStyles()

const PrivateHeader = ({ ...props }) => {
  let PublicHeaderStyles = PHS()

  return (
    <div className={PublicHeaderStyles.barra}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FLogotipo-Zonda-Vector.svg?alt=media&token=e3d435da-9937-473b-8994-f4ac1cd7b850&_gl=1*da6o61*_ga*MTA2MTM0NDcyMC4xNjc3MTAxNzk3*_ga_CW55HF8NVT*MTY4NjU5MzU3Mi4xOC4xLjE2ODY1OTM2NDkuMC4wLjA."
        alt="Logo de la Empresa"
        className={PublicHeaderStyles.logo}
      />
    </div>
  )
}

export default PrivateHeader

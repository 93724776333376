import Inventario from "../views/inventario";
import InventarioDetail from "../views/inventario/detail";
import Producto from "../views/producto";
import ProductoDetail from "../views/producto/detail";
import Recepcion from "../views/recepcion";
import Reportes from "../views/reportes";
import Servicios from "../views/servicios";
import Usuarios from "../views/usuario";
import UsuariosDetail from "../views/usuario/detail";
import Convenios from "../views/convenios";
import CheckList from "../views/checklist";
import CheckListDetail from "../views/checklist/detail";
import Empresa from "../views/empresa";
import EmpresaDetail from "../views/empresa/detail";
import Carro from "../views/carro";
import CarroDetail from "../views/carro/detail";
import Area from "../views/area";
import AreaDetail from "../views/area/detail";
import Sucursal from "../views/sucursal";
import SucursalDetail from "../views/sucursal/detail";
import CheckListEmpleado from "../views/checklistEmpleados/index";
import CheckListEmpleadoDetail from "../views/checklistEmpleados/detail";
import ManoObra from "../views/manoObra";
import ManoObraDetail from "../views/manoObra/detail";
import Proveedor from "../views/proveedores";
import ProveedorDetail from "../views/proveedores/detail";
import OrdenServicio from "../views/ordenServicio/index";
import OrdenServicioDetail from "../views/ordenServicio/detail";
import ChecklistsCampamento from "../views/checklistCampamento";
import ChecklistsCampamentoDetail from "../views/checklistCampamento/detail";
import Compras from "../views/compras/index";
import ComprasDetail from "../views/compras/detail";

const enlaces = [
  {
    nombre: "Inventario",
    tipo: "NavLink",
    ruta: "/inventario",
    permiso: "inventarios",
    vista: Inventario,
    detail: InventarioDetail,
  },
  {
    nombre: "Productos",
    tipo: "NavLink",
    ruta: "/producto",
    permiso: "productos",
    vista: Producto,
    detail: ProductoDetail,
  },
  {
    nombre: "Recepción",
    tipo: "NavLink",
    ruta: "/recepcion",
    permiso: "recepcion",
    vista: Recepcion,
  },
  {
    nombre: "Reportes",
    tipo: "NavLink",
    ruta: "/reportes",
    permiso: "reportes",
    vista: Reportes,
  },
  {
    nombre: "Servicios",
    tipo: "NavLink",
    ruta: "/servicios",
    permiso: "servicios",
    vista: Servicios,
  },
  {
    nombre: "Usuarios",
    tipo: "NavLink",
    ruta: "/usuarios",
    permiso: "usuarios",
    vista: Usuarios,
    detail: UsuariosDetail
  },
  {
    nombre: "Compras",
    tipo: "NavLink",
    permiso: "compras",
    vista: Compras,
    detail: ComprasDetail,
  },
  {
    nombre: "Convenios",
    tipo: "NavLink",
    ruta: "/convenios",
    permiso: "convenios",
    vista: Convenios,
  },
  {
    nombre: "Check List",
    tipo: "NavLink",
    ruta: "/checklist",
    permiso: "checklists",
    vista: CheckList,
    detail: CheckListDetail,
  },
  {
    nombre: "Proyectos",
    tipo: "NavLink",
    ruta: "/areas",
    permiso: "areas",
    vista: Area,
    detail: AreaDetail,
  },
  {
    nombre: "Empresas",
    tipo: "NavLink",
    ruta: "/empresas",
    permiso: "empresas",
    vista: Empresa,
    detail: EmpresaDetail,
  },
  {
    nombre: "Carros",
    tipo: "NavLink",
    permiso: "carros",
    vista: Carro,
    detail: CarroDetail,
  },
  {
    nombre: "Sucursales",
    tipo: "NavLink",
    permiso: "sucursales",
    vista: Sucursal,
    detail: SucursalDetail,
  },
  {
    nombre: "Check List Empleados",
    tipo: "NavLink",
    permiso: "checklistsEmpleados",
    vista: CheckListEmpleado,
    detail: CheckListEmpleadoDetail,
  },
  {
    nombre: "Check List Campamentos",
    tipo: "NavLink",
    permiso: "checklistsCampamento",
    vista: ChecklistsCampamento,
    detail: ChecklistsCampamentoDetail,
  },
  {
    nombre: "Mano de Obra",
    tipo: "NavLink",
    permiso: "manosObra",
    vista: ManoObra,
    detail: ManoObraDetail,
  },
  {
    nombre: "Proveedores",
    tipo: "NavLink",
    permiso: "proveedores",
    vista: Proveedor,
    detail: ProveedorDetail,
  },
  {
    nombre: "Orden de Servicio",
    tipo: "NavLink",
    permiso: "ordenServicios",
    vista: OrdenServicio,
    detail: OrdenServicioDetail,
  },
];

export default enlaces;

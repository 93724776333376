import React from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
  Image
} from "@react-pdf/renderer"
import moment from "moment"
import logo from "../images/logo.png"
import uncheckedCheckbox from "../images/213-2132782_unchecked-checkbox-icon-free-download-png-and-vector-unchecked-checkbox-icon-free.png"
import checkedCheckbox from "../images/checked-checkbox-icon-checkbox-ico-115632629493xkxpf63d8.png"

const PdfOrdenServicio = ({ data }) => {
  let folio = data.idOrdenServicio
  let fechaInicio = moment(data.creado).format("L")
  let fechaFinal = moment(data.fechaFinal).format("L")
  let vehiculo = data.checklists?.datoschecklists?.carros?.economico
  let kilometraje = data.checklists?.gasolinachecklists?.kilometraje
  let operador = data.checklists?.datoschecklists?.operador
  let refacciones = data?.refacciones || []
  let notas = data.checklists?.gasolinachecklists?.notas || []
  let observaciones = data.observaciones || []
  let servicios = data.manosObraOrdenServicio || []

  console.log("observaciones:", observaciones)
  console.log("manosObra:", servicios)

  const tiposUnicos = [
    "Frenos",
    "S. Elec",
    "Tren M.",
    "Soldadura",
    "A/C",
    "Cristales",
    "Transmisión",
    "Preventivo",
    "Motor",
    "Llanta",
    "Corr. Prog."
  ]

  // Servicios que coinciden con los tipos únicos
  const tiposSeleccionados = servicios.map((servicio) =>
    servicio.tipo.trim().toLowerCase()
  )

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image source={logo} style={{ width: "30%" }} />
          <Text style={styles.title}>ORDEN DE TRABAJO</Text>
          <Text style={styles.ordenDeTrabajo}>No. {folio}</Text>
        </View>

        <View style={styles.infoSection}>
          <View
            style={[
              styles.infoRow,
              { flexDirection: "row", flexWrap: "wrap", alignItems: "center" }
            ]}
          >
            <Text style={styles.infoLabel}>VEHÍCULO: {vehiculo}</Text>
            <Text style={styles.infoLabel}>ODÓMETRO: {kilometraje} km</Text>
            <Text style={styles.infoLabel}>FECHA INICIO: {fechaInicio}</Text>
          </View>
        </View>

        <View style={styles.infoSection}>
          <View
            style={[
              styles.infoRow,
              { flexDirection: "row", flexWrap: "wrap", alignItems: "center" }
            ]}
          >
            <Text style={styles.infoLabel}>TURNO: {}</Text>
            <Text style={styles.infoLabel}>TIEMPO REPARACION: {}</Text>
            <Text style={styles.infoLabel}>FECHA TERMINO: {fechaFinal}</Text>
          </View>
        </View>

        <View style={styles.infoRow}>
          <Text style={styles.infoLabel2}>NOMBRE OPERADOR: {operador}</Text>
        </View>

        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            marginBottom: "10px"
          }}
        >
          <View style={styles.subtitle}>
            <Text>FAVOR DE LLENAR CADA UNO DE LOS DATOS INDICADOS</Text>
          </View>
        </View>

        {/* Checkbox section */}
        <View style={styles.checkboxSection}>
          <Text style={styles.subtitle}>
            TIPO DE MANTENIMIENTO (EN CASO DE SER MÁS DE UNO, INDICAR TIEMPO)
          </Text>
          <View style={styles.checkboxContainer}>
            {tiposUnicos.map((tipo, index) => (
              <View style={styles.checkboxRow} key={index}>
                <Text style={styles.checkboxLabel}>{tipo}</Text>
                <Image
                  source={
                    tiposSeleccionados.includes(tipo.trim().toLowerCase())
                      ? checkedCheckbox
                      : uncheckedCheckbox
                  }
                  style={{ width: 12, height: 12, marginLeft: 5 }}
                />
              </View>
            ))}
          </View>
        </View>

        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            marginBottom: "10px"
          }}
        >
          <View style={styles.subtitle}>
            <Text>CAUSA O FALLA</Text>
          </View>
        </View>
        <View style={styles.partsSection}>
          <View style={styles.partsTable}>
            {notas.map((item, i) => {
              return (
                <View
                  key={i}
                  style={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black"
                  }}
                >
                  <Text
                    style={{
                      paddingLeft: "10px",
                      fontSize: "12px",
                      color: "black"
                    }}
                  >
                    {i + 1}-. {item.nota}
                  </Text>
                </View>
              )
            })}
          </View>
        </View>

        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            marginBottom: "10px"
          }}
        >
          <View style={styles.subtitle}>
            <Text>CORRECCION DE FALLA</Text>
          </View>
        </View>

        <View style={styles.partsSection}>
          <View style={styles.partsTable}>
            {observaciones.map((item, i) => {
              return (
                <View
                  key={i}
                  style={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black"
                  }}
                >
                  <Text
                    style={{
                      paddingLeft: "10px",
                      fontSize: "12px",
                      color: "black"
                    }}
                  >
                    {i + 1}-. {item.observacion}
                  </Text>
                </View>
              )
            })}
          </View>
        </View>

        {/* Parts Section */}
        <View style={styles.partsSection}>
          <View
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              backgroundColor: "yellow",
              border: "1px",
              fontSize: 14
            }}
          >
            <Text>REFACCIONES UTILIZADAS</Text>
          </View>
          <View style={styles.partsTable}>
            <View style={styles.partsRow}>
              <Text style={styles.partsCellNumber}>No.</Text>
              <Text style={styles.partsCellName}>No. Parte</Text>
              <Text style={styles.partsCellName}>NOMBRE DE LA REFACCIÓN</Text>
              <Text style={styles.partsCellName}>CANTIDAD</Text>
            </View>
          </View>
          <View style={styles.partsTable}>
            {refacciones.length > 0 ? (
              Object.values(
                refacciones.reduce((acc, refacccion) => {
                  const idProducto = refacccion.productos.idProducto

                  if (!acc[idProducto]) {
                    acc[idProducto] = { ...refacccion }
                  } else {
                    acc[idProducto].cantidad += refacccion.cantidad
                  }

                  return acc
                }, {})
              ).map((refacccion, i) => (
                <View style={styles.partsRow} key={i}>
                  <Text style={styles.partsCellNumber}>{i + 1}</Text>
                  <Text style={styles.partsCellName}>
                    {refacccion?.productos?.numParte}
                  </Text>
                  <Text style={styles.partsCellName}>
                    {refacccion?.productos?.nombreProducto}
                  </Text>
                  <Text style={styles.partsCellName}>
                    {refacccion?.cantidad}
                  </Text>
                </View>
              ))
            ) : (
              <Text>No hay refacciones disponibles</Text>
            )}
          </View>
        </View>

        {/* Signatures Section */}
        <View style={styles.signaturesSection}>
          <View style={styles.signatureBlock}>
            <Text style={styles.signatureLabel}>FIRMA OPERADOR</Text>
          </View>
          <View style={styles.signatureBlock}>
            <Text style={styles.signatureLabel}>FIRMA SUPERVISOR</Text>
          </View>
          <View style={styles.signatureBlock}>
            <Text style={styles.signatureLabel}>FIRMA TÉCNICO</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "red",
    marginLeft: "-60px"
  },
  ordenDeTrabajo: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4
  },
  infoSection: {
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  infoRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    width: "100%"
  },
  infoLabel: {
    width: "24%",
    fontWeight: "bold",
    textAlign: "center"
  },
  infoLabelFull: {
    width: "100%",
    fontWeight: "bold",
    textAlign: "center"
  },
  checkboxSection: {
    marginBottom: 20
  },
  checkboxRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5
  },
  checkboxLabel: {
    marginLeft: 5
  },
  section: {
    marginBottom: 20
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10
  },
  partsSection: {
    marginBottom: 20
  },
  partsTableHeader: {
    backgroundColor: "yellow",
    padding: 5,
    textAlign: "center",
    fontWeight: "bold"
  },
  partsTable: {
    borderWidth: 1,
    borderColor: "#000"
  },
  partsRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000"
  },
  partsCellNumber: {
    width: "10%",
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    textAlign: "center"
  },
  partsCellName: {
    width: "65%",
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    textAlign: "left"
  },
  partsCellQuantity: {
    width: "25%",
    padding: 5,
    textAlign: "center"
  },
  signaturesSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20
  },
  signatureBlock: {
    width: "30%",
    borderTopWidth: 1,
    borderColor: "#000",
    paddingTop: 5,
    textAlign: "center"
  },
  signatureLabel: {
    fontSize: 10,
    fontWeight: "bold"
  },
  checkboxContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Para que los checkboxes se envuelvan a la siguiente línea
    justifyContent: "space-between",
    marginRight: 5
  }
})

const blobToExport = async (data) => {
  return await pdf(<PdfOrdenServicio data={data} />).toBlob()
}

export default blobToExport

import React, { useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import Input from "../../components/Input";
import { Grid, IconButton } from "@mui/material";
import Vista from "../../components/Vista";
import dateComponent from "../../components/dateComponent";
import { useModal } from "../../hooks/useModal";
import VisibilityIcon from '@mui/icons-material/Visibility';

const busquedaDefault = {
  articulo: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay(),
};


const Compras = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault });
  const { setContainerModal, setOpen } = useModal();
  const [modalData, setModalData] = useState({ tipo: "", data: [] });

  const moreInfo = (dataList) => {
    setModalData({ data: dataList });
    setContainerModal(
      <div style={{ textAlign: "center" }}>
        <>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#157CC1", color: "#FFFFFF" }}>
                <th style={{ padding: "10px" }}>Nombe</th>
                <th style={{ padding: "10px" }}>Cantidad</th>
                <th style={{ padding: "10px" }}>Precio</th>
                <th style={{ padding: "10px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((e, i) => (
                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#D9D9D9" }}>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.articulo}</td>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.cantidad}</td>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.precio}</td>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    );
    setOpen(true);
  };

  const dataTable = {
    id: {
      nombre: "No. de compra",
      content: (e) => {
        return e.idCompra;
      },
    },
    fecha: {
      nombre: "Fecha",
      content: (e) => {
        return e.creado;
      },
    },
    status: {
      nombre: "Status",
      content: (e) => {
        return e.status;
      },
    },
    total: {
      nombre: "Total de la compra",
      content: (e) => {
        return e.total;
      },
    },
    detalles: {
      nombre: "Detalles de la compra",
      content: (e) => {
        const detalleCompras = _.get(e, "detalleCompras", []);
        return (
          <>
            {detalleCompras.length > 0 && (
              <IconButton onClick={() => moreInfo(detalleCompras)}>
                <VisibilityIcon />
              </IconButton>
            )}
          </>
        );
      },
    }
  };

  return (
    <Vista>
      <TableCustom
        tipo={"idCompra"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        buscador={[
          <Grid item xs={10} sm={8} sx={{ marginRight: "10px", height: "41px" }} key={1}>
            <Input
              value={busqueda?.articulo}
              placeholder={"Articulo"}
              typeInput={"onlyInput"}
              nombreBusqueda="articulo"
              onChange={(e) =>
                setBusqueda({ ...busqueda, articulo: e.target.value })
              }
            />
          </Grid>
        ]}
        expand={"detalleCompras, sucursal"}
        permisoCheck={"compras"}
        nombreBusqueda={"articulo"}
      />
    </Vista>
  );
};

export default Compras;

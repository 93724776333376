import React, { useState } from "react"
import { useAuth } from "../../hooks/useAuth"
import FullScreenLoader from "../../components/FullScreenLoader"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import Vista from "../../components/Vista"
import { HomeStyles } from "../../utils/styles"
import { IconButton } from "@mui/material"
import { HiClipboardList } from "react-icons/hi"
import { FaCar } from "react-icons/fa"
import { FaCarBattery } from "react-icons/fa"
import { NavLink } from "react-router-dom/cjs/react-router-dom.min"
import { MdInventory } from "react-icons/md"
import { BsFileEarmarkRichtextFill } from "react-icons/bs"
import { AiOutlineFieldTime } from "react-icons/ai"
import { FaUserTag } from "react-icons/fa"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import { AiFillShopping } from "react-icons/ai"

//----------------------------------------------------------------------------
import {
  Document,
  Image,
  PDFViewer,
  Page,
  Text,
  View
} from "@react-pdf/renderer"
import gasolinaDashboard from "../../images/gasolinaDashboard.png"
import pickupPlanos from "../../images/pickupPlanos.png"
import sedanPlanos from "../../images/sedanPlanos.png"
import toneladaPlanos from "../../images/toneladaPlanos.png"
import gruaPlanos from "../../images/gruaPlanos.png"
import zIndex from "@mui/material/styles/zIndex"
//----------------------------------------------------------------------------

let vs = HomeStyles()
const Home = () => {
  let HomeStyles = vs()
  const { loginLoading } = useAuth()
  const [permiso] = useLocalStorage("permiso", null)
  console.log(localStorage.getItem("permiso"))

  if (loginLoading) return <FullScreenLoader />
  return (
    <Vista style={{ display: "block" }}>
      {/* <PDFViewer>
        <pdfOrdenServicio />
      </PDFViewer> */}
      <div className={HomeStyles.photoContainer}>
        <img
          className={HomeStyles.image}
          src="https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2Fimagen_web_zonda.png?alt=media&token=7d54e2ae-ec5f-4d2d-b2ad-da876a4b8d99&_gl=1*84gx10*_ga*MTA2MTM0NDcyMC4xNjc3MTAxNzk3*_ga_CW55HF8NVT*MTY4NjU5MzU3Mi4xOC4xLjE2ODY1OTM2MjUuMC4wLjA."
        ></img>
        <img
          className={HomeStyles.logo}
          src="https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FLogotipo-Zonda-Vector.svg?alt=media&token=e3d435da-9937-473b-8994-f4ac1cd7b850&_gl=1*da6o61*_ga*MTA2MTM0NDcyMC4xNjc3MTAxNzk3*_ga_CW55HF8NVT*MTY4NjU5MzU3Mi4xOC4xLjE2ODY1OTM2NDkuMC4wLjA."
        />
      </div>
      <div className={HomeStyles.MenuContainer}>
        <div className={HomeStyles.optionsContainer}>
          <div className={HomeStyles.titleOptions}>
            <div className={HomeStyles.title}>Selecciona una acción</div>
          </div>
          <div className={HomeStyles.optionContainer}>
            <div className={HomeStyles.options}>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["checklists"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["checklists"] & 1) === 1)}
                    to={"/checklists"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["checklists"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <HiClipboardList
                          className={
                            (permiso["checklists"] & 1) === 1
                              ? HomeStyles.icon
                              : HomeStyles.iconDisabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["checklists"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Checklist
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["checklistsEmpleados"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["checklistsEmpleados"] & 1) === 1)}
                    to={"/checklistsEmpleados"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["checklistsEmpleados"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <HiClipboardList
                          className={
                            (permiso["checklistsEmpleados"] & 1) === 1
                              ? HomeStyles.icon
                              : HomeStyles.iconDisabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["checklistsEmpleados"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Checklist Empleados
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["carros"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["carros"] & 1) === 1)}
                    to={"/carros"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["carros"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <FaCar
                          className={
                            (permiso["carros"] & 1) === 1
                              ? HomeStyles.icon2
                              : HomeStyles.icon2Disabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["carros"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Carros
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["productos"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["productos"] & 1) === 1)}
                    to={"/productos"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["productos"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <FaCarBattery
                          className={
                            (permiso["productos"] & 1) === 1
                              ? HomeStyles.icon2
                              : HomeStyles.icon2Disabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["productos"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Productos
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["inventarios"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["inventarios"] & 1) === 1)}
                    to={"/inventarios"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["inventarios"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <MdInventory
                          className={
                            (permiso["inventarios"] & 1) === 1
                              ? HomeStyles.icon
                              : HomeStyles.iconDisabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["inventarios"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Inventarios
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["manosObra"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["manosObra"] & 1) === 1)}
                    to={"/manosObra"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["manosObra"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <AiOutlineFieldTime
                          className={
                            (permiso["manosObra"] & 1) === 1
                              ? HomeStyles.icon
                              : HomeStyles.iconDisabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["manosObra"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Manos de Obra
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["proveedores"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["proveedores"] & 1) === 1)}
                    to={"/proveedores"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["proveedores"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <FaUserTag
                          className={
                            (permiso["proveedores"] & 1) === 1
                              ? HomeStyles.icon
                              : HomeStyles.iconDisabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["proveedores"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Proveedores
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["ordenServicios"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["ordenServicios"] & 1) === 1)}
                    to={"/ordenServicios"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["ordenServicios"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <BsFileEarmarkRichtextFill
                          className={
                            (permiso["ordenServicios"] & 1) === 1
                              ? HomeStyles.icon
                              : HomeStyles.iconDisabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["ordenServicios"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Orden de servicio
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>

              <div className={HomeStyles.option}>
                <div
                  className={
                    (permiso["compras"] & 1) === 1
                      ? HomeStyles.optionButton
                      : HomeStyles.optionButtonDisabled
                  }
                >
                  <NavLink
                    exact
                    disabled={!((permiso["compras"] & 1) === 1)}
                    to={"/compras"}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      disabled={!((permiso["compras"] & 1) === 1)}
                      sx={{ display: "block", width: "100%", height: "100%" }}
                    >
                      <div className={HomeStyles.optionIcon}>
                        <AiFillShopping
                          className={
                            (permiso["compras"] & 1) === 1
                              ? HomeStyles.icon
                              : HomeStyles.iconDisabled
                          }
                        />
                      </div>
                      <div
                        className={
                          (permiso["compras"] & 1) === 1
                            ? HomeStyles.optionText
                            : HomeStyles.optionTextDisabled
                        }
                      >
                        Compras
                      </div>
                    </IconButton>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Vista>
  )
}

export default Home

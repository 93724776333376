import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"
import { LoadingButton } from "@mui/lab"
import { UsuariosStyle } from "../../utils/styles"
import { useRef, useState } from "react"
import Permiso from "../../components/Permiso"
import { useMessages } from "../../hooks/useMessages"
import servo from "../../services/servo"
import { useGetDatos } from "../../hooks/useGetDatos"

const US = UsuariosStyle()

const Permisos = ({ permisos, setOpen, idUsuario }) => {
  let permisosKeys = Object.keys(permisos)
  const UsuariosStyle = US()
  const ref = useRef(null)
  const [sucursal, setSucursal] = useState(
    permisos.sucursal ? permisos.sucursal : null
  )
  const { setReload } = useGetDatos()
  const { setMessages } = useMessages()
  const [permisoLoading, setPermisoLoading] = useState(false)

  const getPermisosFrom = () => {
    console.log(ref)
    const nuevosPermisos = []
    Array.from(ref.current.children).forEach((row) => {
      if (row.children.length < 2) {
        return
      }
      nuevosPermisos.push({
        nombre: row.children[0].innerHTML,
        ver: row.children[1].children[0].children[0].children[0].checked,
        agregar: row.children[2].children[0].children[0].children[0].checked,
        modificar: row.children[3].children[0].children[0].children[0].checked,
        eliminar: row.children[4].children[0].children[0].children[0].checked
      })
    })
    return nuevosPermisos
  }

  const getPermisosFormat = (_permisosToFormat) => {
    let permisosFormated = {}
    _permisosToFormat.forEach((row) => {
      let totalPermisos = 0
      if (row?.ver) {
        totalPermisos += 1
      }
      if (row?.agregar) {
        totalPermisos += 2
      }
      if (row?.modificar) {
        totalPermisos += 4
      }
      if (row?.eliminar) {
        totalPermisos += 8
      }
      permisosFormated[row.nombre] = totalPermisos
    })

    return permisosFormated
  }

  const guardarPermisos = async (idUsuario) => {
    setPermisoLoading(true)
    let datos = getPermisosFormat(getPermisosFrom())
    let formato = { ...datos, idUsuario, idSucursal: sucursal?.idSucursal }
    let res = await servo.post("permiso/guardar", formato, "Usuarios", false)
    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }
    setPermisoLoading(false)
    setOpen(false)
    setReload(true)
  }

  return (
    <div style={{ minHeight: "80%" }}>
      <div className={UsuariosStyle?.odin}>
        <Paper sx={{ maxHeight: "90%" }}>
          <TableContainer sx={{ maxHeight: "80%" }}>
            <Table
              sx={{ maxHeight: "50%" }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow className={UsuariosStyle?.tableHead}>
                  <TableCell className="headColor">Permiso</TableCell>
                  <TableCell className="headColor">Ver</TableCell>
                  <TableCell className="headColor">Agregar</TableCell>
                  <TableCell className="headColor">Modificar</TableCell>
                  <TableCell className="headColor">Deshabilitar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody ref={ref}>
                <>
                  <TableRow className={UsuariosStyle?.tableHead}>
                    <TableCell>
                      <AutocompleteDeluxe
                        labelToShow="Sucursales"
                        labelProp="nombreSucursal"
                        nombre="sucursales"
                        value={sucursal}
                        setDato={setSucursal}
                        background={"#FFFFFF"}
                      />
                    </TableCell>
                  </TableRow>
                  {permisosKeys &&
                    permisosKeys?.length > 0 &&
                    permisosKeys.map((permiso, i) => {
                      if (permiso !== "idUsuario" && permiso !== "idSucursal") {
                        return (
                          <Permiso
                            nombre={permiso}
                            permiso={permisos[permiso]}
                            color={i % 2 === 0 ? "#F2F2F2" : "#D9D9D9"}
                            llave={i}
                          />
                        )
                      }
                    })}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <div className={UsuariosStyle?.buttons}>
        <Button onClick={() => setOpen(false)} className="cancelar">
          Cancelar
        </Button>
        <LoadingButton
          loading={permisoLoading || !sucursal}
          onClick={() => guardarPermisos(idUsuario)}
          className="guardar"
        >
          Guardar
        </LoadingButton>
      </div>
    </div>
  )
}

export default Permisos

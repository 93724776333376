import { CircularProgress } from "@mui/material"
import React, { useState, useEffect, useCallback } from "react"
import Servo from "../services/servo"
import { Autocomplete, TextField } from "@mui/material"
import _each from "lodash/each"
import { InputStyles } from "../utils/styles"

let IS = InputStyles()
const propertyAccesor = (rootObj, accesor = "") =>
  accesor.split(".").reduce((obj, prop) => obj?.[prop], rootObj)

const AutocompleteDeluxe = ({
  background,
  nombre,
  dato,
  setDato,
  labelToShow,
  labelProp,
  idProp,
  grouped,
  required = "false",
  filtro = {},
  ...props
}) => {
  const [datos, setDatos] = useState(null)
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(true)
  let InputStyles = IS()

  const getDatos = useCallback(async () => {
    setLoading(true)
    let filtroString = ""
    if (Object.keys(filtro).length > 0) {
      _each(Object.keys(filtro), (f) => {
        filtroString += "&" + f + "=" + filtro[f]
      })
    }
    try {
      let res = await Servo.get(nombre + "?" + filtroString)
      setDatos(res?.cuerpo?.resultado)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getDatos()
  }, [])
  return (
    <div style={{ width: "100%" }}>
      {!loading ? (
        <div>
          {required === "true" ? (
            <div className={InputStyles.text}>
              <p>{labelToShow}</p>
              <p className={InputStyles.required}>*</p>
            </div>
          ) : (
            <div className={InputStyles.text}>
              <p>{labelToShow}</p>
            </div>
          )}
          <div>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option[idProp] === value}
              sx={{
                width: "100%",
                border: "none",
                height: "41px",
                border: "2px solid #C7C7C7",
                backgroundColor: "red",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  outline: "none",
                  "& .MuiAutocomplete-endAdornment": {
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "32px"
                  },
                  "& .MuiAutocomplete-input": {
                    padding: "0 45px 0 10px !important",
                    border: "none"
                  }
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  height: "41px",
                  border: "none",
                  borderRadius: "10px"
                },
                "& .MuiFilledInput-root": {
                  border: "none",
                  "& .MuiFilledInput-input": {
                    border: "none",
                    color: "red",
                    // backgroundColor:"green",
                    padding: "0px !important",
                    borderRadius: "10px"
                  }
                },
                "& div": {
                  border: "none"
                  // backgroundColor:"violet"
                }
              }}
              disablePortal
              id="autocompleteDeluxeVersion-1.1"
              value={dato}
              onChange={(e, v) => setDato(v)}
              options={datos
                ?.filter((dato) =>
                  dato[labelProp]
                    .toLowerCase()
                    .trim()
                    .includes(search.toLowerCase().trim())
                )
                .slice(0, 10)}
              getOptionLabel={(option) => propertyAccesor(option, labelProp)}
              renderInput={(params) => (
                <TextField
                  size="Small"
                  variant="outlined"
                  sx={{
                    fontFamily: "poppins",
                    backgroundColor: background != "" ? background : "",
                    // backgroundColor:"red",
                    height: "41px",
                    backgroundColor: "#F2F2F2",
                    borderRadius: "7px",
                    "& label": {
                      height: "20px",
                      backgroundColor: "green",
                      padding: "0"
                    },
                    "& div": {
                      border: "none",
                      height: "100%",
                      // paddingTop: "10px",
                      "& .MuiFilledInput-input": {
                        color: "red"
                      },
                      "& input": {
                        padding: "0",
                        height: "100%",
                        // backgroundColor: "blue",
                        height: "14px",
                        fontFamily: "poppins",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "10px",
                        letterSpacing: "0.07em",
                        color: "#AAAAAA"
                        // marginBottom:"10px"
                      }
                    }
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  {...params}
                  // label={labelToShow}
                />
              )}
              {...props}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default AutocompleteDeluxe

// marginRight:"px",
// backgroundColor:"green",
// backgroundColor:"violet"

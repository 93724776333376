import React, { useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import Grid from "@mui/material/Grid"
import Hidden from "@mui/material/Hidden"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { EstilosButton, InventarioStyles } from "../utils/styles"
import SelectFormat from "../components/SelectFormat"
import Input from "../components/Input"
import { BsSearch } from "react-icons/bs"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import servo from "../services/servo"
import _ from "lodash"
import { AiOutlineReload } from "react-icons/ai"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { CircularProgress, IconButton, Switch } from "@mui/material"
import { Button } from "@mui/material"
import { BiPlus } from "react-icons/bi"
import Acciones from "./Acciones"
import { useGetDatos } from "../hooks/useGetDatos"

let VS = InventarioStyles()
let VS2 = EstilosButton()
export default function StickyHeadTable({
  seeDisabledOption = true,
  Actions = true,
  buscador,
  filtersDefault = true,
  expand = "",
  busqueda = {},
  permisoCheck,
  dataTable,
  tipo,
  nombreBusqueda,
  setBusqueda,
  filtroCustom = false
}) {
  let EstilosButton = VS2()
  let InventarioStyles = VS()

  //AREA DE STATES
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [showHabilit, setShowHabilit] = useState(false)
  const [datos, setDatos] = useState(null)
  const { reload, setReload } = useGetDatos()
  const [loading, setLoading] = useState(false)
  const [permiso] = useLocalStorage("permiso", null)
  const [datosHabilitados, setDatosHabilitados] = useState(null)
  const [datosDeshabilitados, setDatosDeshabilitados] = useState(null)

  //PARA CREAR LOS FILTROS
  const busquedaPaginacion = {
    ver: "",
    nombre: ""
  }

  const [verBusqueda, setVerBusqueda] = useState({ ...busquedaPaginacion })

  //GET DATOS EXTRAE LOS DATOS DE LA BD
  //Y LOS FILTRA POR HABILITADOS Y DESHABILITADOS
  const camelToKebab = (str) => {
    return str.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)
  }

  const getDatos = async (fechas = null) => {
    setLoading(true)

    const kebabPermisoCheck = camelToKebab(permisoCheck)

    let res = await servo.get(
      `${kebabPermisoCheck}?expand=${expand}&fecha-inicio=${
        fechas !== null ? fechas?.fechaInicio : busqueda?.fechaInicio
      }&fecha-final=${
        fechas !== null ? fechas?.fechaFinal : busqueda?.fechaFinal
      }`
    )

    let data = res?.cuerpo?.resultado
    let datosHab = data.filter(
      (datosHabilitado) => datosHabilitado.eliminado === null
    )
    let datosDes = data.filter(
      (datosHabilitado) => datosHabilitado.eliminado !== null
    )

    setDatos(res?.cuerpo?.resultado)
    setDatosHabilitados(datosHab)
    setDatosDeshabilitados(datosDes)

    setLoading(false)
  }

  useEffect(async () => {
    getDatos()
  }, [])

  useEffect(() => {
    if (reload) {
      getDatos()
      setReload(false)
    }
  }, [reload])

  //SEGUNDA PARTE DE LOS DATOS
  //LOS DATOS LLEGAN AQUI DEPENDIENDO DE SI HAY ALGO ESCRITO
  //EN EL FILTRO DE BUSQUEDA POR ESCRITURA
  const filterData = (datosParaFiltrar) => {
    if (datosParaFiltrar?.length > 0) {
      let datosFiltrados = [...datosParaFiltrar]
      if (filtroCustom) {
        datosFiltrados = datosFiltrados.filter(filtroCustom)
        return datosFiltrados
      } else {
        Object.keys(busqueda).forEach((k) => {
          if (k != "fechaInicio" && k != "fechaFinal") {
            datosFiltrados = datosFiltrados?.filter((d) => {
              if (busqueda[k] != "" && busqueda[k] != "Todos") {
                return d[k].toLowerCase().includes(busqueda[k].toLowerCase())
              } else {
                return d
              }
            })
          }
        })
        return datosFiltrados
      }
    }
  }

  //PRIMERA PARTE DE LOS DATOS
  //AQUI ES LA PRIMERA INSTANCIA DE LOS DATOS, ES EL FILTRO DE ESCRITURA
  //DEPENDIENDO DE ESTE ES EL PRIMER RENDERIZADO
  const filtrar = (nombre, data) => {
    let dataResult = []
    if (!nombre) {
      dataResult = data
    } else {
      dataResult = data.filter((datos) =>
        datos[nombreBusqueda].toLowerCase().includes(nombre.toLowerCase())
      )
    }
    return filterData(dataResult)
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  //MOSTRAR O NO DESHABILITADOS
  const handleChange = () => {
    if (showHabilit === true) {
      setShowHabilit(false)
    } else {
      setShowHabilit(true)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <div className={InventarioStyles.tableContainer}>
        <div>
          {/* CONTENEDOR DE FILTROS */}
          {filtersDefault ? (
            <Grid container className={InventarioStyles.filterContainer}>
              {/* CONTENEDOR DEL INPUT BUSCADOR */}
              {!filtroCustom && (
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={5}
                  sx={{
                    "@media (max-width: 600px)": {
                      marginTop: "12px",
                      order: "2"
                    },
                    "@media (min-width: 600px)": {
                      order: "1",
                      marginTop: "0px",
                      paddingRight: "10px"
                    },
                    "@media (min-width: 900px)": {
                      // height:"41px",
                      marginTop: "0px",
                      order: "2"
                    }
                  }}
                >
                  <Input
                    typeInput={"search"}
                    valor={verBusqueda?.nombre}
                    onChange={(e) =>
                      setVerBusqueda({ ...verBusqueda, nombre: e.target.value })
                    }
                    placeholder="Buscar"
                    nombreBusqueda={
                      nombreBusqueda
                        .replace(/([A-Z])/g, " $1")
                        .charAt(0)
                        .toUpperCase() +
                      nombreBusqueda.replace(/([A-Z])/g, " $1").slice(1)
                    }
                    Icon={BsSearch}
                    IconStyle={{
                      width: "20px",
                      height: "17px",
                      color: "#157CC1"
                    }}
                  />
                </Grid>
              )}

              {/* CONTENEDOR DEL BOTON DE AGREGAR  */}
              <Grid
                item
                xs={10}
                sm={filtroCustom != false ? 10 : 4}
                md={2}
                sx={{
                  "@media (max-width: 600px)": {
                    order: "1"
                  },
                  "@media (min-width: 600px)": {
                    order: "2"
                  },
                  "@media (min-width: 900px)": {
                    order: "3"
                  }
                }}
              >
                {(permiso[permisoCheck] & 2) === 2 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "41px"
                    }}
                  >
                    <NavLink
                      exact
                      to={"/" + permisoCheck + "/crear"}
                      className={InventarioStyles.navStyle}
                    >
                      <Button
                        sx={{
                          width: "100%",
                          height: "41px",
                          backgroundColor: "#157CC1",
                          borderRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          textTransform: "none"
                        }}
                        variant="contained"
                      >
                        <BiPlus className={EstilosButton.icon} />
                        <p className={EstilosButton.font}>
                          Agregar{" "}
                          {permisoCheck === "areas"
                            ? "proyectos"
                            : permisoCheck}
                        </p>
                      </Button>
                    </NavLink>
                  </div>
                ) : (
                  <div className={EstilosButton.buttonAddDisabled}>
                    <Button
                      disabled
                      sx={{
                        width: "100%",
                        height: "41px",
                        backgroundColor: "#157CC1",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "none"
                      }}
                      variant="contained"
                    >
                      <BiPlus className={EstilosButton.icon} />
                      <p className={EstilosButton.font}>
                        Agregar {permisoCheck}
                      </p>
                    </Button>
                  </div>
                )}
              </Grid>

              {/* CONTENEDOR DE BOTON RELOAD */}
              <Grid
                item
                xs={2}
                sm={filtroCustom != false ? 2 : 1}
                md={1}
                sx={{
                  "@media (max-width: 600px)": {
                    order: "1",
                    paddingLeft: "10px"
                  },
                  "@media (min-width: 600px)": {
                    paddingLeft: "10px",
                    order: "2"
                  },
                  "@media (min-width: 900px)": {
                    order: "3"
                  }
                }}
              >
                <IconButton
                  disabled={loading}
                  onClick={(e) => {
                    getDatos()
                  }}
                  style={{
                    width: "100%",
                    height: "41px",
                    borderRadius: "10px",
                    backgroundColor: "#0FB11F",
                    border: "1px solid #0FB11F",
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                  }}
                >
                  <AiOutlineReload
                    style={{ color: "#FFFFFF", width: "80%", height: "80%" }}
                  />
                </IconButton>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={filtroCustom ? 9 : 4}
                sx={{
                  display: "flex",
                  "@media (max-width: 600px)": {
                    marginTop: "12px",
                    order: "3"
                  },
                  "@media (min-width: 600px)": {
                    marginTop: "12px",
                    order: "3"
                  },
                  "@media (min-width: 900px)": {
                    marginTop: "0px",
                    order: "1"
                  }
                }}
              >
                {/* ESTE ES EL VER */}
                <Hidden smDown={!filtroCustom ? true : false}>
                  <Grid
                    item
                    sm={0}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "9.5px",
                      letterSpacing: "7%",
                      color: "#AAAAAA",
                      justifyContent: "center",
                      "@media (min-width: 1200px)": {
                        marginLeft: "10px"
                      }
                    }}
                  >
                    <div>
                      <p style={{ marginRight: "5px" }}>Ver:</p>
                    </div>
                  </Grid>
                </Hidden>

                {/* ESTE ES EL DE LAS PAGINAS */}
                <Grid
                  item
                  xs={!filtroCustom ? 2 : 3}
                  sm={2}
                  sx={{ marginRight: "10px" }}
                >
                  <SelectFormat
                    value={rowsPerPage}
                    // placeholder={"Ver"}
                    onChange={(e) => {
                      setRowsPerPage(e.target.value)
                    }}
                    datos={[10, 25, 50, 100]}
                  />
                </Grid>

                {/* ESTE ES EL TEXTO FILTROS */}

                <Hidden smDown={true} mdDown={!filtroCustom ? false : true}>
                  <Grid
                    item
                    sm={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "9.5px",
                      letterSpacing: "7%",
                      color: "#AAAAAA",
                      justifyContent: "center",
                      marginRight: "10px"
                    }}
                  >
                    <div>{filtroCustom ? <p></p> : <p>Filtros:</p>}</div>
                  </Grid>
                </Hidden>
                {!filtroCustom && buscador}
              </Grid>
              {filtroCustom != false &&
                buscador.map((item, i) => {
                  return item
                })}
            </Grid>
          ) : (
            buscador
          )}

          <div
            className={InventarioStyles.inhabilitArea}
            style={{ height: !seeDisabledOption && "60px" }}
          >
            {seeDisabledOption && (
              <div
                className={InventarioStyles.deshabilitArea}
                style={{ paddingLeft: !seeDisabledOption && "12px" }}
              >
                <Switch
                  checked={showHabilit}
                  onClick={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <p className={InventarioStyles.font}>Ver Deshabilitados</p>
              </div>
            )}
            <div
              className={InventarioStyles.dateArea}
              style={{ height: !seeDisabledOption && "100%" }}
            >
              <div className={InventarioStyles.firstDateArea}>
                <div className={InventarioStyles.dateTitleArea}>
                  Fecha inicio
                </div>
                <div className={InventarioStyles.dateTimeArea}>
                  <input
                    type="date"
                    value={busqueda?.fechaInicio}
                    className={InventarioStyles.dateDesing}
                    onChange={(e) => {
                      setBusqueda({ ...busqueda, fechaInicio: e.target.value })
                      getDatos({
                        fechaInicio: e.target.value,
                        fechaFinal: busqueda?.fechaFinal
                      })
                    }}
                  />
                </div>
              </div>
              <div className={InventarioStyles.endDateArea}>
                <div className={InventarioStyles.dateTitleArea}>
                  Fecha final
                </div>
                <div className={InventarioStyles.dateTimeArea}>
                  <input
                    type="date"
                    value={busqueda?.fechaFinal}
                    className={InventarioStyles.dateDesing}
                    onChange={(e) => {
                      setBusqueda({ ...busqueda, fechaFinal: e.target.value })
                      getDatos({
                        fechaInicio: busqueda?.fechaInicio,
                        fechaFinal: e.target.value
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={InventarioStyles.tableSpace}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {!loading && filterData(datos) ? (
              <>
                <TableContainer sx={{}}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {Object.keys(dataTable).map((column, i) => (
                          <TableCell
                            key={i}
                            sx={{
                              backgroundColor: "#157CC1",
                              height: "46px",
                              fontFamily: "'Poppins', sans-serif",
                              fontWeight: "500",
                              fontSize: "12px",
                              lineHeight: "9.5px",
                              color: "white",
                              textAlign: "center",
                              lineHeight: "13px"
                            }}
                          >
                            {dataTable[column].nombre}
                          </TableCell>
                        ))}
                        {Actions &&
                          ((permiso[permisoCheck] & 4) === 4 ||
                            (permiso[permisoCheck] & 8) === 8) && (
                            <TableCell
                              sx={{
                                backgroundColor: "#157CC1",
                                height: "46px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "9.5px",
                                color: "white",
                                textAlign: "center"
                              }}
                            >
                              Acciones
                            </TableCell>
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filtrar(
                        verBusqueda.nombre,
                        showHabilit === false
                          ? datosHabilitados
                          : datosDeshabilitados
                      )
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <React.Fragment key={index}>
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                {Object.keys(dataTable).map((column, i) => {
                                  return (
                                    <TableCell
                                      key={i}
                                      sx={{
                                        backgroundColor:
                                          index % 2 ? "#FFFFFF" : "#D9D9D9",
                                        height: "60px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        lineHeight: "9.5px",
                                        color: "#717171",
                                        textAlign: "center",
                                        lineHeight: "13px"
                                      }}
                                    >
                                      {dataTable[column].content(row)}
                                    </TableCell>
                                  )
                                })}

                                {Actions &&
                                  ((permiso[permisoCheck] & 4) === 4 ||
                                    (permiso[permisoCheck] & 8) === 8) && (
                                    <TableCell
                                      sx={{
                                        backgroundColor:
                                          index % 2 ? "#FFFFFF" : "#D9D9D9",
                                        height: "60px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        lineHeight: "9.5px",
                                        color: "#717171",
                                        textAlign: "center"
                                      }}
                                    >
                                      <Acciones
                                        permisoCheck={permisoCheck}
                                        recargar={getDatos}
                                        id={row[tipo]}
                                        tipo={tipo}
                                        isEliminado={row.eliminado}
                                        data={row}
                                      />
                                    </TableCell>
                                  )}
                              </TableRow>
                            </React.Fragment>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  component="div"
                  count={
                    filterData(
                      showHabilit === false
                        ? datosHabilitados
                        : datosDeshabilitados
                    )?.length
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} de ${count} ${permisoCheck}`
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "70px",
                    ".MuiInputBase-root": {
                      display: "none"
                    },
                    ".MuiTablePagination-toolbar": {
                      display: "flex",
                      justifyContent: "center",
                      width: "30%",
                      color: "rgb(41, 39, 39)",
                      height: "35px",
                      ".MuiTablePagination-actions": {
                        marginLeft: "0px"
                      }
                    },
                    ".MuiTablePagination-selectLabel": {
                      display: "none"
                    },
                    ".MuiTablePagination-displayedRows": {
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "9.5px",
                      color: "#717171"
                    },
                    ".css-1psng7p-MuiTablePagination-spacer ": {
                      display: "none"
                    },
                    ".MuiTablePagination-spacer": {
                      padding: "0px"
                    },
                    "@media (min-width: 600px)": {
                      ".MuiTablePagination-toolbar": {
                        padding: "0px"
                      }
                    }
                  }}
                />
              </>
            ) : (
              <>
                <div className={InventarioStyles.loadingArea}>
                  <CircularProgress />
                </div>
              </>
            )}
          </Paper>
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import PrivateRoute from "../components/PrivateRoute"
import Home from "../views/home"
import PrivateHeader from "../containers/PrivateHeader"
import enlaces from "../const/enlaces"
import SideBar from "../components/SideBar"

const PrivateRoutes = () => {
  const [rutas, setRutas] = useState([])

  // Función para cargar las rutas
  const getRutas = () => {
    let preRutas = []
    enlaces.forEach((enlace, i) => {
      preRutas.push(
        <PrivateRoute
          exact
          key={i}
          component={enlace.vista}
          path={"/" + enlace.permiso}
          permisoCheck={enlace.permiso}
        />
      )
      preRutas.push(
        <PrivateRoute
          exact
          key={i}
          component={enlace.detail}
          path={"/" + enlace.permiso + "/crear"}
          permisoCheck={enlace.permiso}
        />
      )
      preRutas.push(
        <PrivateRoute
          exact
          key={i}
          component={enlace.detail}
          path={"/" + enlace.permiso + "/editar/:id"}
          permisoCheck={enlace.permiso}
        />
      )
    })
    setRutas([...preRutas])
  }

  useEffect(() => {
    getRutas()

    // Guardar la última ruta visitada en el localStorage
    const currentPath = window.location.pathname
    // Solo guardar si no estamos en la ruta de inicio
    if (currentPath !== "/inicio") {
      localStorage.setItem("lastVisitedPath", currentPath)
    }
  }, [])

  // Recuperar la última ruta visitada al recargar
  useEffect(() => {
    const lastVisitedPath = localStorage.getItem("lastVisitedPath")
    // Si hay una ruta guardada y no estamos en la ruta de inicio, redirigir
    if (lastVisitedPath && lastVisitedPath !== window.location.pathname) {
      // Evitar que redirija si estamos en la página de inicio
      if (window.location.pathname === "/inicio") {
        return
      }
      window.history.pushState(null, "", lastVisitedPath)
    }
  }, [])

  // Asegurarse de que las rutas se carguen correctamente
  if (!rutas || rutas.length === 0) {
    return <div>Cargando rutas...</div>
  }

  return (
    <Router>
      <SideBar />
      <PrivateHeader />
      <Switch>
        {rutas}
        <Route exact path="/inicio" component={Home} />

        {/* Solo redirigir al home si ninguna ruta es válida */}
        <Route path="*" component={() => <Redirect to="/inicio" />} />
      </Switch>
    </Router>
  )
}

export default PrivateRoutes

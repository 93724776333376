import React from "react"

const GetDatosContext = React.createContext()

export function GetDatosProvider(props) {
  const [reload, setReload] = React.useState(false)

  const memData = React.useMemo(() => {
    return {
      reload,
      setReload
    }
  }, [setReload, reload])

  return <GetDatosContext.Provider value={memData} {...props} />
}

export function useGetDatos() {
  const context = React.useContext(GetDatosContext)
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: getDatos context not defined."
  }
  return context
}

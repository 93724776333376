import React, { useState } from "react"
import TableCustom from "../../components/TableCustom"
import Input from "../../components/Input"
import { CircularProgress, Grid, IconButton } from "@mui/material"
import Vista from "../../components/Vista"
import dateComponent from "../../components/dateComponent"
import SettingsIcon from "@mui/icons-material/Settings"
import { useModal } from "../../hooks/useModal"
import Permisos from "./permiso"

const Usuario = ({ onClick, onKeyDown, ...props }) => {
  const busquedaDefault = {
    correo: "",
    fechaInicio: dateComponent.getFirstDayOfYear(),
    fechaFinal: dateComponent.getActualDay()
  }
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault })
  const { setOpen, setContainerModal } = useModal()

  const openModal = (permisos, idUsuario) => {
    setContainerModal(
      <Permisos permisos={permisos} setOpen={setOpen} idUsuario={idUsuario} />
    )
  }

  let dataTable = {
    nombreProducto: {
      nombre: "Nombre Completo",
      content: (row) => {
        return row.nombreCompleto
      }
    },
    categoria: {
      nombre: "Correo",
      content: (row) => {
        return row.correo
      }
    },
    permisos: {
      nombre: "Permisos",
      content: (row) => {
        return (
          <IconButton
            onClick={(e) => {
              // duplicarPermisos(row?.permisos[0]);
              openModal(row?.permisos[0], row?.idUsuario)
            }}
          >
            <SettingsIcon />
          </IconButton>
        )
      }
    }
  }

  return (
    <Vista>
      <TableCustom
        tipo={"idUsuario"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        buscador={[
          <Grid
            item
            xs={10}
            sm={8}
            sx={{
              "@media (max-width: 600px)": {
                margin: "0px"
              },
              marginRight: "10px",
              height: "41px"
            }}
            key={1}
          >
            <Input
              value={busqueda?.correo}
              placeholder={"Correo"}
              typeInput={"onlyInput"}
              nombreBusqueda="correo"
              onChange={(e) =>
                setBusqueda({ ...busqueda, correo: e.target.value })
              }
            />
          </Grid>
        ]}
        expand={"permisos.sucursal"}
        permisoCheck={"usuarios"}
        nombreBusqueda={"nombreCompleto"}
      />
    </Vista>
  )
}

export default Usuario

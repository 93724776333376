import { makeStyles } from "@mui/styles"

const AvatarStyles = () => {
  const styles = makeStyles((theme) => ({
    img: {
      height: "169px",
      width: "169px",
      borderRadius: "100%"
    }
  }))
  return styles
}

const dropzoneStyle = {
  border: "2px dashed #ccc",
  padding: "20px",
  textAlign: "center",
  width: "50%",
  minHeight: "150px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px"
}

const buttonStyleToT = {
  width: "170px",
  height: "50px",
  backgroundColor: "#157CC1",
  fontFamily: "poppins",
  borderRadius: "10px",
  border: "none",
  fontWeight: "500",
  fontSize: "14px",
  letterSpacing: "0.07em",
  color: "#FFFFFF",
  marginLeft: "2px",
  "@media (min-width: 600px)": {
    marginBottom: "6px"
  },
  "&:hover": {
    backgroundColor: "#1565c0",
    cursor: "pointer",
    marginTop: "30px"
  }
}

const PublicHeaderStyles = () => {
  const styles = makeStyles((theme) => ({
    barra: {
      top: "0",
      width: "100%",
      backgroundColor: "#157CC1",
      height: "45px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      "@media (max-width: 600px)": {
        height: "60px" // Ajusta la altura de la barra en pantallas pequeñas
      }
    },
    contactos: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "10px"
    },
    media: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "10px"
    },
    logo: {
      height: "35px",
      width: "auto",
      maxHeight: "90%",
      marginLeft: "10px",
      marginRight: "10px",
      "@media (max-width: 600px)": {
        height: "30px",
        marginRight: "5px"
      },
      "@media (max-width: 400px)": {
        height: "25px",
        marginRight: "5px"
      }
    }
  }))
  return styles
}

const PublicFooterStyles = () => {
  const styles = makeStyles((theme) => ({
    barra: {
      position: "fixed",
      bottom: "0",
      width: "100%",
      backgroundColor: "#157CC1",
      height: "80px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  }))
  return styles
}

const PrivateHeaderStyles = () => {
  const styles = makeStyles((theme) => ({
    barra: {
      position: "fixed",
      top: "0",
      width: "100%",
      backgroundColor: "#157CC1",
      height: "35px"
    }
  }))
  return styles
}

const PrivateFooterStyles = () => {
  const styles = makeStyles((theme) => ({
    barra: {
      position: "fixed",
      bottom: "0",
      width: "100%",
      backgroundColor: "#157CC1",
      height: "80px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    contactos: {
      display: "block",
      marginLeft: "80px"
    },
    botonSoporte: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "80px"
    }
  }))
  return styles
}

const ButtonStyles = () => {
  const styles = makeStyles((theme) => ({
    primary: {
      height: "48px",
      backgroundColor: "#157CC1",
      color: "#F2F2F2",
      width: "120px",
      borderRadius: "7px",
      fontSize: "15px",
      border: "none",
      padding: "0 20px",
      fontWeight: "600",
      cursor: "pointer"
    },
    secondary: {
      backgroundColor: "#FCD659",
      color: "#157CC1",
      height: "32px",
      width: "120px",
      borderRadius: "7px",
      fontSize: "15px",
      border: "none",
      fontWeight: "600",
      cursor: "pointer"
    },
    default: {
      backgroundColor: "gray",
      color: "white",
      height: "32px",
      width: "120px",
      borderRadius: "7px",
      fontSize: "15px",
      border: "none",
      fontWeight: "600",
      cursor: "pointer"
    }
  }))
  return styles
}
const ButtonMenuStyles = () => {
  const styles = makeStyles((theme) => ({
    BotonMenu: {
      height: "56px",
      backgroundColor: "#007AC1",
      color: "#FFFFFF",
      width: "264px",
      borderRadius: "7px",
      fontSize: "15px",
      border: "none",
      fontWeight: "600",
      cursor: "pointer",
      marginTop: "50px"
    }
  }))
  return styles
}

const ContactosStyles = () => {
  const styles = makeStyles((theme) => ({
    ubicacion: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        color: "#F2F2F2",
        margin: "0",
        fontSize: "12px",
        letterSpacing: "-0.015em"
      },
      marginLeft: "10px"
    },
    correo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        color: "#F2F2F2",
        margin: "0",
        fontSize: "12px",
        letterSpacing: "-0.015em"
      },
      marginLeft: "10px"
    },
    correo2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& p": {
        color: "#FFFFFF",
        margin: "0",
        fontSize: "12px",
        letterSpacing: "-0.015em",
        fontWeight: "400"
      },
      marginLeft: "10px"
    },
    icono: {
      color: "#F2F2F2",
      marginRight: "5px",
      fontSize: "20px"
    },
    iconoButton: {
      color: "#F2F2F2",
      marginRight: "10px",
      fontSize: "25px",
      "&:hover": {
        color: "#FCD659"
      },
      cursor: "pointer"
    },
    media: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  }))
  return styles
}

const InputStyles = () => {
  const styles = makeStyles((theme) => ({
    text: {
      display: "flex",
      color: "#646464",
      height: "50%",
      width: "100%",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "21px"
    },
    inputSize: {
      border: "2px solid #C7C7C7",
      display: "flex",
      width: "100%",
      height: "41px",
      backgroundColor: "#F2F2F2",
      borderRadius: "10px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #C7C7C7"
      },
      "& svg": {
        position: "absolute",
        color: "#AAAAAA",
        fontSize: "16px",
        alignItems: "center"
      }
    },
    formInput: {
      display: "flex",
      width: "100%",
      height: "41px",
      backgroundColor: "#F2F2F2",
      borderRadius: "10px",
      border: "2px solid #C7C7C7",
      "& svg": {
        position: "absolute",
        color: "#AAAAAA",
        fontSize: "16px"
      }
    },
    inputContent: {
      display: "block",
      height: "100%",
      width: "100%",
      border: "2px solid #C7C7C7",
      borderRadius: "10px",
      "&:focus-within": {
        // outline: "none",
        border: "2px solid #606060"
      }
    },
    inputCantidad: {
      display: "block",
      height: "100%",
      width: "30px",
      border: "2px solid #C7C7C7",
      borderRadius: "10px",
      "&:focus-within": {
        // outline: "none",
        border: "2px solid #606060"
      }
    },
    inputContentDate: {
      display: "flex",
      height: "50%",
      width: "100%",
      border: "2px solid #C7C7C7",
      borderRadius: "10px",
      "&:focus-within": {
        // outline: "none",
        border: "2px solid #606060"
      }
    },
    formContent: {
      display: "block",
      height: "100%",
      width: "100%"
    },
    onlyInput: {
      display: "flex",
      height: "100%",
      width: "100%",
      fontSize: "14px",
      letterSpacing: "0.07em",
      fontFamily: "poppins",
      fontWeight: "medium",
      borderRadius: "5px",
      border: "none",
      backgroundColor: "#F2F2F2",
      paddingLeft: "12px",
      borderRadius: "10px",
      color: "#949494",
      outline: "0",
      "&::placeholder": {
        // paddingLeft:"12px",
        color: "#949494"
      }
    },
    input: {
      color: "#AAAAAA",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "0.07em",
      height: "56px",
      width: "100%",
      backgroundColor: "#FFFFFF",
      borderRadius: "7px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      boxShadow: "0px 0 10px #00000054",
      "& input": {
        backgroundColor: "transparent",
        // paddingLeft: "24px",
        height: "100%",
        border: "none",
        letterSpacing: "-0.015em",
        // padding: "5px",
        marginLeft: "5px",
        color: "#666666",
        fontSize: "16px",
        width: "-webkit-fill-available",
        "&:focus-visible": {
          outline: "none"
        }
      }
    },
    inputSearch: {
      height: "41px",
      width: "100%",
      backgroundColor: "#F2F2F2",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      border: "2px solid #c7c7c7",
      color: "#c7c7c7",
      "&:focus-within": {
        border: "2px solid #606060"
      },
      "& input": {
        height: "41px",
        fontFamily: "poppins",
        color: "#949494",
        fontWeight: "medium",
        backgroundColor: "transparent",
        fontFamily: "poppins",
        paddingLeft: "12px",
        border: "none",
        letterSpacing: "-0.015em",
        fontSize: "14px",
        width: "-webkit-fill-available",
        outline: "none",
        "&::placeholder": {
          color: "#949494",
          width: "200px"
        }
      }
    },
    icon: {
      color: "#666F666",
      fontSize: "14px",
      marginRight: "10px"
    },
    inputForm: {
      color: "#AAAAAA",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "0.07em",
      height: "41px",
      width: "100%",
      backgroundColor: "#F2F2F2",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      border: "2px solid #C7C7C7",
      paddingLeft: "12px",
      height: "100%",
      "&:focus-visible": {
        outline: "none"
      }
    },
    inputFormularys: {
      color: "#949494",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "0.07em",
      height: "41px",
      width: "100%",
      backgroundColor: "#F2F2F2",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "12px",
      outline: "none",
      border: "none",
      "&:focus-visible": {
        outline: "none"
      }
    },
    datePicker: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      "& .MuiTextField-root": {
        height: "41px",
        width: "100%",
        padding: 0
      }
    },
    inputFormIcon: {
      color: "#AAAAAA",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "0.07em",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "100%",
      paddingLeft: "24px",
      border: "none",
      backgroundColor: "transparent",
      "&:focus-visible": {
        outline: "none"
      },
      iconForm: {
        width: "100%",
        height: "100&"
      }
    },
    required: {
      color: "#C11515",
      fontWeight: "bold"
    }
  }))
  return styles
}

const HomeStyles = () => {
  const styles = makeStyles((theme) => ({
    photoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "40vh"
      // backgroundColor: "red",
    },
    image: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      "@media (max-width: 600px)": {
        objectPosition: "center"
      }
    },
    logo: {
      position: "absolute",
      "@media (max-width: 600px)": {
        width: "270px",
        height: "70px"
      },
      "@media (min-width: 600px)": {
        width: "340px",
        height: "90px"
      },
      "@media (min-width: 1200px)": {
        width: "340px",
        height: "90px"
      },
      "@media (min-width: 1500px)": {
        width: "340px",
        height: "90px"
      }
    },
    MenuContainer: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: "100%",
      // backgroundColor: "violet",
      "@media (max-width: 600px)": {
        height: "350px"
      }
    },
    optionsContainer: {
      position: "absolute",
      borderRadius: "14px",
      backgroundColor: "#FFFFFF",
      "@media (max-width: 350px)": {
        top: "-60px",
        width: "90%",
        height: "280px"
      },
      "@media (min-width: 350px)": {
        top: "-60px",
        width: "82%",
        height: "320px"
      },
      "@media (min-width: 600px)": {
        top: "-50px",
        width: "50%",
        height: "350px"
      },
      "@media (min-width: 1200px)": {
        top: "-35px",
        width: "630px",
        height: "400px"
      }
    },
    titleOptions: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: "100%",
      height: "25%",
      "@media (max-width: 350px)": {
        height: "20%"
      },
      "@media (min-width: 350px)": {
        height: "18%"
      },
      "@media (min-width: 1200px)": {
        height: "20%"
      },
      "@media (min-width: 1500px)": {
        height: "15%"
      }
      // paddingBottom:"18px"
      // backgroundColor: "red",
    },
    title: {
      color: "#007AC1",
      fontWeight: "bold",
      fontSize: "17px",
      lineHeight: "9.5px",
      fontFamily: "poppins",
      marginBottom: "13px",
      "@media (max-width: 350px)": {
        fontSize: "15px"
      }
    },
    optionContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "85%",
      "@media (max-width: 350px)": {
        height: "80%"
      },
      "@media (min-width: 350px)": {
        height: "82%"
      },
      "@media (min-width: 1200px)": {
        height: "65%"
      },
      "@media (min-width: 1500px)": {
        height: "57%"
      }
      // backgroundColor: "green",
    },
    options: {
      display: "flex",
      flexWrap: "wrap",
      width: "70%",
      height: "90%",
      "@media (min1-width: 1200px)": {
        width: "70%"
      }
      // backgroundColor: "violet",
    },
    option: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
      height: "50%",
      "@media (min-width: 1200px)": {
        width: "25%",
        height: "110px"
      }
    },
    optionButton: {
      backgroundColor: "#007AC1",
      // paddingTop:"5px",
      width: "90%",
      height: "90%",
      borderRadius: "14px"
    },
    optionButtonDisabled: {
      backgroundColor: "#AAAAAA",
      // paddingTop:"5px",
      width: "90%",
      height: "90%",
      borderRadius: "14px"
    },
    optionIcon: {
      width: "100%",
      height: "60%",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center"
    },
    icon: {
      width: "43px",
      height: "43px",
      color: "#FCD659"
    },
    iconDisabled: {
      width: "43px",
      height: "43px",
      color: "#D9D9D9"
    },
    icon2: {
      width: "40px",
      height: "40px",
      color: "#FCD659"
    },
    icon2Disabled: {
      width: "40px",
      height: "40px",
      color: "#D9D9D9"
    },
    optionText: {
      paddingTop: "5px",
      width: "100%",
      height: "30%",
      fontSize: "12px",
      fontFamily: "poppins",
      lineHeight: "9.5px",
      fontWeight: "bold",
      color: "#FFFFFF"
    },
    optionTextDisabled: {
      paddingTop: "5px",
      width: "100%",
      height: "30%",
      fontSize: "12px",
      fontFamily: "poppins",
      lineHeight: "9.5px",
      fontWeight: "bold",
      color: "#D9D9D9"
    }
  }))
  return styles
}

const InputMessageStyles = () => {
  const styles = makeStyles((theme) => ({
    inputMessage: {
      height: "1/2",
      backgroundColor: "#de77779c",
      border: "1px solid #E3828F",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center",
      "& p": {
        height: "100%",
        border: "none",
        letterSpacing: "-0.015em",
        padding: "5px",
        color: "#424141",
        fontSize: "14px"
      }
    }
  }))
  return styles
}

const VistaStyles = () => {
  const styles = makeStyles((theme) => ({
    vista: {
      width: "100%",
      minHeight: "-webkit-calc(100vh - 115px)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    vista2: {
      width: "100%",
      minHeight: "-webkit-calc(100vh - 35px)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundColor: "#DCDCDC",
      display: "flex",
      justifyContent: "center"
    }
  }))

  return styles
}

const VistaPrivadaStyles = () => {
  const styles = makeStyles((theme) => ({
    vista: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// INPUT ESTILOS                                                           ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const EstilosInput = () => {
  const styles = makeStyles((theme) => ({
    default: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      "& div": {
        width: "100%"
      },
      "& #containerInput": {
        height: "100%",
        border: "1px solid #C7C7C7",
        borderRadius: "11px"
      },
      "& div input": {
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        border: "none",
        color: "#AAAAAA",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "18px",
        "&:focus-visible": {
          outline: "none"
        }
      },
      "& div p": {
        fontSize: "14px",
        lineHeigth: "21px",
        textAlign: "left",
        color: "#646464",
        fontWeight: "700",
        margin: 0
      }
    },
    ine: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      "& div": {
        width: "100%"
      },
      "& #containerInput": {
        border: "1px solid #C7C7C7",
        borderRadius: "11px",
        padding: "0 10px"
      },
      "& div input": {
        width: "100%",
        height: "100%",
        border: "none",
        color: "#AAAAAA",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "18px",
        "&:focus-visible": {
          outline: "none"
        }
      },
      "& div p": {
        fontSize: "14px",
        lineHeigth: "21px",
        textAlign: "left",
        color: "#646464",
        fontWeight: "700",
        margin: 0
      }
    }
  }))
  return styles
}

const EstilosButton = () => {
  const styles = makeStyles((theme) => ({
    buttonAddDisabled: {
      cursor: "not-allowed"
    },
    actionsArea: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      "@media (max-width: 900px)": {
        justifyContent: "space-around"
      }
    },
    actionItem: {
      width: "20%",
      height: "100%",
      display: "flex",
      justifyContent: "center"
    },
    actionEdit: {
      color: "#157CC1"
    },
    actionDelete: {
      color: "#157CC1",
      "&:hover": {
        color: "#e71633"
      }
    },
    actionUndelete: {
      color: "#157CC1",
      "&:hover": {
        color: "#4BB543"
      }
    },
    actionItemDisabled: {
      width: "20%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      cursor: "not-allowed"
    },
    font: {
      fontFamily: "poppins",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      lineHeight: "14px"
    },
    icon: {
      color: "#FFFFFF",
      fontSize: "16px"
    },
    iconDisabled: {
      color: "red",
      fontSize: "16px"
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// SideBar Styles                                                          ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const SlideBarStyles = () => {
  const styles = makeStyles((theme) => ({
    MenuContainer: {
      "@media (min-width: 900px)": {
        width: "121px",
        height: "32px",
        display: "flex",
        // justifyContent: "flex-start",
        position: "absolute",
        top: 50,
        left: 30
      },
      "@media (max-width: 900px)": {
        width: "121px",
        height: "32px",
        position: "absolute"
      }
    },
    BotonMenu: {
      "@media (max-width: 900px)": {
        justifyContent: "flex-start",
        paddingLeft: "20px",
        marginTop: "70px",
        width: "70px"
      },
      width: "100%",
      height: "32px",
      backgroundColor: "#157CC1",
      borderRadius: "12px",
      color: "#FCD659",
      fontSize: "16px",
      border: "1px solid #157CC1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0 5px",
      fontWeight: "500",
      "& svg": {
        fontSize: "1.8rem"
      }
    },
    CerrarMenuContainer: {
      backgroundColor: "#157CC1",
      width: "100%",
      display: "flex"
    },
    closeIcon: {
      color: "#FCD659",
      fontSize: "40px"
    },
    listContainer: {
      width: "100%",
      height: "48px",
      "& a": {
        textDecoration: "none"
      }
    },
    listItem: {
      width: "100%",
      height: "100%",
      backgroundColor: "#157CC1",
      border: "none",
      fontSize: "16px",
      color: "FFFFFF",
      fontWeight: "medium",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#2690D7"
      },
      "& p": {
        padding: "0 20px",
        margin: "0px",
        textDecoration: "none"
      }
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// INVENTARIOS                                                             ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const InventarioStyles = () => {
  const styles = makeStyles((theme) => ({
    tableContainer: {
      width: "95%",
      height: "100%",
      marginTop: "70px",
      // paddingTop: "40px",
      "@media (max-width: 900px)": {
        marginTop: "50px"
      }
    },
    filterContainer: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      display: "flex",
      padding: "10px",
      marginBottom: "10px",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      justifyContent: "space-between"
    },
    textFilter: {
      width: "100%",
      height: "41px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    font: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "9.5px",
      letterSpacing: "7%",
      color: "#AAAAAA"
    },
    loadingArea: {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0px 30px 0px"
    },
    navStyle: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none"
    },
    inhabilitArea: {
      "@media (max-width: 600px)": {
        display: "block",
        height: "120px",
        width: "100%"
      },
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "55px",
      backgroundColor: "white",
      marginBottom: "10px",
      borderRadius: "10px"
    },
    deshabilitArea: {
      "@media (max-width: 600px)": {
        height: "35%",
        width: "100%"
      },
      "@media (min-width: 600px)": {
        width: "30%"
      },
      "@media (min-width: 900px)": {
        height: "50%",
        width: "20%"
      },
      display: "flex",
      alignItems: "center"
    },
    dateArea: {
      "@media (max-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "65%"
      },
      "@media (min-width: 600px)": {
        width: "70%",
        height: "100%"
      },
      "@media (min-width: 900px)": {
        height: "100%",
        width: "50%"
      },
      display: "flex",
      alignItems: "center"
    },
    firstDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      }
    },
    endDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      },
      padding: "0px 10px 0px 10px"
    },
    dateTitleArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        height: "100%",
        width: "40%",
        paddingBottom: "0px"
      },
      "@media (max-width: 600px)": {
        display: "flex",
        alignItems: "flex-end",
        height: "35%",
        fontSize: "12px",
        width: "50%",
        paddingBottom: "5px"
        // backgroundColor:"red",
      },
      lineHeight: "13px",
      fontWeight: "500",
      color: "#AAAAAA"
    },
    dateTimeArea: {
      height: "65%",
      width: "100%"
    },
    dateDesing: {
      width: "90%",
      height: "80%",
      borderRadius: "10px",
      backgroundColor: "#F2F2F2",
      border: "none",
      padding: "0 5px 0 5px",
      fontFamily: "poppins",
      fontSize: "14px",
      fontWeight: "medium",
      color: "#949494",
      "&::webkit-datetime-edit-month-field": {
        color: "blue"
      },
      "@media (min-width: 600px)": {
        minHeight: "100%"
      }
    },
    odin: {
      width: "100%",
      overflowY: "auto"
    },
    tableHead: {
      "& .headColor": {
        background: "#157CC1",
        color: "#F2F2F2",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      }
    },
    tableRow: {
      "& .rowColor": {
        height: "53px",
        // background: "blue",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      },
      "& .totalRowColor": {
        height: "53px",
        fontWeight: "bold",
        background: "#F2F2F2",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      }
    },
    container: {
      width: "100%",
      minHeight: "100%"
      // backgroundColor:"blueviolet"
    },
    formArea: {
      "@media (max-width: 600px)": {
        width: "100%",
        minHeight: "50%"
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "50%"
      // backgroundColor:"red"
    },
    formDataContainer: {
      "@media (max-width: 600px)": {
        width: "98%",
        height: "100%"
      },
      "@media (min-width: 600px)": {
        width: "60%"
      },
      "@media (min-width: 1200px)": {
        width: "50%"
      },
      width: "40%",
      height: "50%",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      padding: "10px"
    },

    formAreaTable: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      minHeight: "330px"
    },
    buttonAreaContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      width: "100%",
      height: "60px",
      backgroundColor: "#DCDCDC"
    },
    buttonContainer: {
      "@media (max-width: 600px)": {
        width: "100%",
        marginRight: "20px"
      },
      display: "flex",
      justifyContent: "flex-end",
      height: "100%",
      width: "70%"
      // backgroundColor:"red"
    },
    buttonSave: {
      width: "170px",
      height: "50px",
      backgroundColor: "#157CC1",
      fontFamily: "poppins",
      borderRadius: "10px",
      border: "none",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      "@media (min-width: 600px)": {
        marginBottom: "6px"
      },
      "&:hover": {
        backgroundColor: "#1565c0",
        cursor: "pointer"
      }
    },
    quantity: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "40px"
      // backgroundColor: "red",
    },
    quantityContainer: {
      "@media (max-width: 600px)": {
        width: "100%"
      },
      display: "flex",
      width: "30%",
      height: "100%"
      // backgroundColor: "violet",
    },
    quantityArea: {
      "@media (max-width: 600px)": {
        width: "60%"
        // backgroundColor: "yellow",
      },
      marginRight: "10px",
      width: "60%",
      height: "100%"
    },
    moreLessArea: {
      "@media (max-width: 600px)": {
        width: "40%"
      },
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "40%",
      height: "100%"
      // backgroundColor: "green",
    },
    buttonsArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50%",
      width: "100%"
      // backgroundColor: "violet",
    },
    buttonsMoreLess: {
      backgroundColor: "#D9D9D9",
      width: "80%",
      height: "90%",
      borderRadius: "14px"
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// USE MESSAGES                                                             ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const MessageStyles = () => {
  const styles = makeStyles((theme) => ({
    alert: {
      position: "fixed",
      bottom: "0",
      right: "0",
      top: "auto",
      justifyContent: "flex-end",
      "@media (max-width: 700px)": {
        left: "0%"
      },
      "@media (min-width: 700px)": {
        left: "50%"
      },
      "@media (min-width: 1250px)": {
        left: "70%"
      }
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// USE Modal                                                             ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const ModalStyles = () => {
  const styles = makeStyles((theme) => ({
    odin: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#00000096",
      zIndex: 3,
      maxHeight: "100%",
      "& .thor": {
        position: "absolute",
        top: "50px",
        left: "100px",
        right: "100px",
        bottom: "100px",
        background: "#FFFFFF",
        borderRadius: "10px",
        "@media (max-width: 700px)": {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: "0px"
        },
        "& .magni": {
          position: "relative",
          left: 0,
          right: 0,
          height: "40px",
          display: "flex",
          justifyContent: "flex-end"
        }
      }
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// USE Modal                                                             ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const UsuariosStyle = () => {
  const styles = makeStyles((theme) => ({
    odin: {
      width: "100%",
      overflowY: "auto"
    },
    buttons: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& .cancelar": {
        background: "#C11515",
        color: "#F2F2F2",
        borderRadius: "10px",
        margin: "20px 10px",
        padding: "5px 20px"
      },
      "& .guardar": {
        background: "#157CC1",
        color: "#F2F2F2",
        borderRadius: "10px",
        margin: "20px 10px",
        padding: "5px 20px"
      }
    },
    tableHead: {
      fontFamily: "poppins",
      "& .headColor": {
        background: "#157CC1",
        color: "#F2F2F2",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      }
    },
    tableBody: {
      fontFamily: "poppins",
      "& .bodyColor": {
        textAlign: "center",
        fontSize: "12px",
        fontFamily: "poppins"
      }
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// SELECT ESTILOS                                                          ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const SelectStyles = () => {
  const styles = makeStyles((theme) => ({
    box: {
      width: "100%",
      height: "41px",
      backgroundColor: "#F2F2F2",
      borderRadius: "10px"
      // border: "2px solid #C7C7C7",
    },
    boxItem: {
      width: "100%",
      height: "100%",
      "&	.MuiTablePagination-displayedRows": {
        color: "red"
      },
      "& div": {
        fontFamily: "poppins",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "10px",
        letterSpacing: "0.07em",
        color: "#AAAAAA",
        height: "41px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        "& 	.MuiTablePagination-menuItem": {
          color: "red"
        }
      },
      "& label": {
        height: "14px",
        fontFamily: "poppins",
        fontWeight: "medium",
        fontSize: "14px",
        lineHeight: "10px",
        letterSpacing: "0.07em",
        color: "#949494"
        // backgroundColor:"gray"
      }
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// EDIT Y AGREGAR PRODUCTO ESTILOS                                         ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const productDetails = () => {
  const styles = makeStyles((theme) => ({
    screenSize: {
      width: "95%",
      height: "100%",
      "@media (min-width: 900px)": {
        paddingTop: "50px"
      }
    },
    top: {
      display: "flex",
      alignItems: "center",
      height: "50px",
      width: "100%"
      // backgroundColor: "violet",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "45px",
      width: "100%",
      backgroundColor: "#157CC1",
      borderRadius: "9px",
      color: "#FFFFFF",
      fontWeight: "500"
    },
    formArea: {
      width: "100%",
      height: "100%"
    },
    inputArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80px",
      width: "100%"
    },
    textfieldArea: {
      "@media (min-width: 600px)": {
        height: "80px"
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      width: "100%"
    },
    photoArea: {
      marginTop: "20px",
      "@media (max-width: 600px)": {
        width: "100%",
        height: "450px"
      }
    },
    photoButtons: {
      "@media (max-width: 600px)": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "50px"
      },
      "@media (min-width: 600px)": {
        display: "flex",
        justifyContent: "space-between",
        width: "50%",
        height: "50px"
      },
      "@media (min-width: 900px)": {
        display: "flex",
        justifyContent: "space-between",
        width: "30%",
        height: "50px"
      }
    },
    photoButton: {
      width: "45%",
      height: "100%"
    },
    imageArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: "100%",
      height: "310px",
      backgroundColor: "#FFFFFF",
      borderRadius: "0px 10px 10px 10px",
      "@media (max-width: 600px)": {
        backgroundColor: "transparent"
      }
    },
    imageComponent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      height: "300px",
      width: "234px"
    },
    image: {
      display: "flex",
      justifyContent: "center",
      width: "233px",
      height: "200px"
    },
    imageSRC: {
      display: "flex",
      justifyContent: "center",
      width: "233px",
      height: "200px",
      backgroundColor: "#F2F2F2",
      borderRadius: "21px",
      border: "1px solid #AAAAAA"
    },
    buttonImage: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      height: "40px"
    },
    hiddenCanva: {
      display: "none",
      width: "100%",
      height: "100%"
    },
    hiddenVideo: {
      display: "none",
      width: "100%",
      height: "100%"
    },
    showCanva: {
      display: "flex",
      width: "100%",
      height: "100%"
    },
    showCanvaPhoto: {
      display: "flex"
    },
    showVideo: {
      width: "100%",
      height: "100%"
    },
    showVideoPhoto: {
      height: "100%"
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      height: "80px",
      width: "100%",
      marginBottom: "20px",
      "@media (max-width: 600px)": {
        marginTop: "20px",
        backgroundColor: "transparent",
        alignItems: "flex-start",
        width: "100%"
      }
    },
    buttonAreaEspecial: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      height: "80px",
      width: "60%",
      marginBottom: "20px",
      "@media (max-width: 600px)": {
        marginTop: "20px",
        backgroundColor: "transparent",
        alignItems: "flex-start",
        width: "90%"
      }
    },
    buttonSave: {
      width: "170px",
      height: "50px",
      backgroundColor: "#157CC1",
      fontFamily: "poppins",
      borderRadius: "10px",
      border: "none",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      "@media (min-width: 600px)": {
        marginBottom: "6px"
      },
      "&:hover": {
        backgroundColor: "#1565c0",
        cursor: "pointer"
      }
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// Checklist Styles                                                        ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const ChecklistStyles = () => {
  const styles = makeStyles((theme) => ({
    optionsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100px",
      backgroundColor: "#E7E7E7"
    },
    selectionContainer: {
      "@media (max-width: 600px)": {
        display: "flex",
        width: "100%",
        height: "70%"
      },
      "@media (min-width: 600px)": {
        display: "flex",
        width: "80%",
        height: "70%"
      },
      "@media (min-width: 1024px)": {
        display: "flex",
        width: "60%",
        height: "70%"
      }
    },
    buttonArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "25%",
      height: "100%"
    },
    button: {
      "@media (max-width: 600px)": {
        fontSize: "11px"
      },
      width: "90%",
      lineHeight: "14px",
      height: "40px",
      border: "none",
      backgroundColor: "#FFFFFF",
      borderRadius: "23px",
      color: "#646464",
      fontWeight: "bold",
      fontSize: "14px"
    },
    buttonFocus: {
      "@media (max-width: 600px)": {
        fontSize: "11px"
      },
      width: "90%",
      lineHeight: "14px",
      height: "40px",
      border: "none",
      backgroundColor: "#157CC1",
      borderRadius: "23px",
      color: "#FFFFFF",
      fontWeight: "bold",
      fontSize: "14px"
    },
    componentContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#FFFFFF"
    },
    internContainer: {
      width: "95%",
      height: "600px"
    },
    inputArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80px",
      width: "100%",
      padding: "0px 10px 0px 10px"
    },
    switchContainer: {
      display: "flex",
      alignItems: "center",
      height: "25px",
      width: "60px",
      backgroundColor: "white",
      borderRadius: "20px"
    },
    switchOption1: {
      paddingLeft: 12,
      position: "absolute",
      fontSize: "12px",
      color: "#646464",
      fontWeight: "500"
    },
    switchOption2: {
      paddingLeft: 29,
      position: "absolute",
      zIndex: "5",
      fontSize: "12px",
      color: "#646464",
      fontWeight: "500"
    },
    seguroContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "43px",
      borderRadius: "10px",
      border: "1px solid #C7C7C7",
      backgroundColor: "#E7E7E7",
      color: "#646464"
    },
    itemsSeguro: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%",
      height: "100%"
    },
    font: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "9.5px",
      letterSpacing: "7%",
      color: "#646464"
    },
    buttonSaveArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      height: "80px",
      width: "100%",
      backgroundColor: "#FFFFFF",
      borderRadius: "0px 0px 10px 10px",
      // marginBottom: "20px",
      "@media (max-width: 600px)": {
        marginTop: "10px",
        backgroundColor: "transparent",
        alignItems: "flex-start"
      }
    },
    buttonSave: {
      width: "170px",
      height: "50px",
      backgroundColor: "#157CC1",
      fontFamily: "poppins",
      borderRadius: "10px",
      border: "none",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      "@media (min-width: 600px)": {
        marginBottom: "6px"
      }
    },
    tableArea: {
      width: "100%",
      height: "100%",
      padding: "8px"
    },
    titleArea: {
      display: "flex",
      width: "100%",
      height: "45px",
      border: "1px solid #C7C7C7",
      backgroundColor: "#E7E7E7",
      borderRadius: "10px 10px 0px 0px",
      color: "#646464"
    },
    textArea: {
      display: "flex",
      alignItems: "center",
      width: "70%",
      height: "100%"
    },
    text: {
      paddingLeft: "12px",
      fontSize: "13px",
      fontWeight: "bold"
    },
    optionsBMContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "30%",
      height: "100%"
    },
    optionsArea: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "20px",
      width: "93px",
      backgroundColor: "#C7C7C7",
      color: "#646464",
      borderRadius: "10px",
      fontSize: "13px",
      fontWeight: "bold"
    },
    optionsMMArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "20px",
      width: "93px",
      backgroundColor: "#C7C7C7",
      color: "#646464",
      borderRadius: "10px",
      fontSize: "13px",
      fontWeight: "bold"
    },
    selectsContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      borderRadius: "0px 0px 10px 10px",
      borderLeft: "1px solid #C7C7C7",
      borderRight: "1px solid #C7C7C7",
      borderBottom: "1px solid #C7C7C7"
      // backgroundColor:"red"`
    },
    selectsArea: {
      display: "block",
      margin: "15px 0px 15px 0px",
      width: "100%"
    },
    select: {
      display: "flex",
      width: "100%",
      height: "35px"
    },
    textOption: {
      paddingLeft: "12px",
      fontSize: "12px",
      fontWeight: "400",
      color: "#646464"
    },
    selectArea: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "25px",
      width: "93px",
      color: "#646464"
    },
    selectAreaInput: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "25px",
      width: "93px",
      borderRadius: "5px",
      color: "#FFFFFF"
    },
    selectAreaRed: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "25px",
      width: "93px",
      color: "#FFFFFF",
      "& div": {
        borderRadius: "10px",
        "& input": {
          textAlign: "center",
          backgroundColor: "#ED3F3F",
          color: "#F2F2F2",
          fontWeight: "bold",
          borderRadius: "8px",
          padding: "0"
        }
      }
    },
    selectAreaYellow: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "25px",
      width: "93px",
      color: "#f2f2f2",
      "& div": {
        borderRadius: "10px",
        "& input": {
          textAlign: "center",
          backgroundColor: "#FCCE59",
          color: "#F2F2F2",
          fontWeight: "bold",
          borderRadius: "8px",
          padding: "0"
        }
      }
    },
    selectAreaGreen: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "25px",
      width: "93px",
      color: "#FFFFFF",
      "& div": {
        borderRadius: "10px",
        "& input": {
          textAlign: "center",
          backgroundColor: "#09BA1B",
          color: "#F2F2F2",
          fontWeight: "bold",
          borderRadius: "8px",
          padding: "0"
        }
      }
    },
    colorsArea: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "45px",
      marginTop: "5px"
    },
    colors: {
      display: "flex",
      width: "95%",
      height: "100%",
      backgroundColor: "F6F6F6",
      borderRadius: "5px"
    },
    areaColor: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "33%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center"
    },
    okColor: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "90%",
      height: "80%",
      fontWeight: "bold",
      backgroundColor: "#09BA1B",
      borderRadius: "10px"
    },
    warningColor: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "90%",
      height: "80%",
      fontWeight: "bold",
      borderRadius: "10px",
      backgroundColor: "#FCCE59"
    },
    dangerColor: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "90%",
      height: "80%",
      fontWeight: "bold",
      backgroundColor: "#ED3F3F",
      borderRadius: "10px"
    },

    textColors: {
      fontSize: "10px",
      color: "#f2f2f2",
      fontWeight: "medium"
    },
    areaTextColor: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "50%"
    },
    componentContainerGasolina: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      backgroundColor: "#FFFFFF",
      marginTop: "20px"
    },
    kmContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "84px"
    },
    kmArea: {
      "@media (max-width: 700px)": {
        width: "80%"
      },
      width: "30%",
      height: "100%"
    },
    gasContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "250px",
      "@media (max-width: 4250px)": {
        height: "170px"
      },
      "@media (min-width: 425px && max-width: 600px)": {
        height: "200px"
      }
    },
    gasArea: {
      position: "relative",
      width: "35%",
      "@media (max-width: 425px)": {
        width: "60%"
      },
      "@media (min-width: 425px)": {
        width: "50%"
      },
      "@media (min-width: 600px )": {
        width: "35%"
      },
      "@media (min-width: 900px )": {
        width: "25%"
      },
      "@media (min-width: 1200px)": {
        width: "20%"
      },
      "@media (min-width: 1500px)": {
        width: "15%"
      },
      "@media (min-width: 2000px)": {
        width: "11%"
      }
    },
    photoGasArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: "100%",
      height: "100%"
    },
    areaPhoto: {
      width: "100%"
    },
    photo: {
      width: "100%"
    },
    marker: {
      position: "absolute",
      width: "100%",
      height: "5px",
      bottom: "0",
      transition: "transform 1s"
    },
    markerLine: {
      width: "10%",
      height: "5px",
      backgroundColor: "red"
    },
    sliderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "30%",
      flexWrap: "wrap"
    },
    sliderArea: {
      height: "80%",
      width: "90%",
      maxWidth: "220px"
    },
    inputAreaSelect: {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
      height: "50px",
      width: "100%"
    },
    inputSelect: {
      "@media (max-width: 900px )": {
        width: "25%"
      },
      width: "15%",
      height: "80%"
    },
    carSelectorArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      height: "450px",
      width: "100%"
      // backgroundColor: "red",
    },
    containerSelect: {
      "@media (max-width: 600px )": {
        width: "100%"
      },
      "@media (min-width: 1200px )": {
        width: "30%"
      },
      height: "36px",
      width: "60%"
    },
    areaSelector: {
      "@media (max-width: 600px )": {
        width: "100%"
      },
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "100%",
      width: "100%"
      // backgroundColor: "violet",
    },
    boxSelector: {
      width: "20%",
      height: "100%"
      // backgroundColor:"blue"
    },

    buttonUnselect: {
      "@media (max-width: 600px)": {
        fontSize: "11px"
      },
      width: "100%",
      height: "100%",
      border: "none",

      borderRadius: "12px",
      color: "#646464",
      fontWeight: "bold",
      fontSize: "14px",
      backgroundColor: "#f6f6f6` "
    },
    buttonSelect: {
      "@media (max-width: 600px)": {
        fontSize: "11px"
      },
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: "12px",
      color: "#FFFFFF",
      fontWeight: "bold",
      fontSize: "14px",
      backgroundColor: "#157CC1"
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      height: "400px",
      width: "100%",
      marginTop: "5px"
    },
    photoContainer: {
      "@media (max-width: 600px )": {
        width: "98%"
      },
      "@media (min-width: 1200px )": {
        width: "40%"
      },
      maxWidth: "500px",
      width: "65%",
      height: "100%",
      backgroundColor: "#F2F2F2",
      position: "relative",
      borderRadius: "50px"
    },
    photoModels: {
      width: "100%",
      height: "100%",
      backgroundSize: "cover"
    },
    pointContainer: {
      width: "20px",
      height: "20px",
      backgroundColor: "#157CC1",
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      borderRadius: "50%",
      fontSize: "12px"
    },
    observacionContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "84px",
      marginBottom: "20px"
    },
    observacionArea: {
      "@media (max-width: 600px)": {
        width: "98%"
      },
      "@media (min-width: 1200px)": {
        width: "30%"
      },
      width: "60%",
      height: "100%"
    },
    DeletePointsContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "60px",
      margin: "10px 0 5px 0"
    },
    sizeButtonDelete: {
      display: "flex",
      justifyContent: "center",
      width: "30%",
      height: "100%"
    },
    buttonDelete: {
      backgroundColor: "#C11515",
      borderRadius: "10px",
      height: "100%",
      width: "30%",
      border: "none",
      color: "#FFFFFF",
      fontWeight: "bold",
      "@media (max-width: 600px)": {
        width: "100%"
      },
      "@media (min-width: 600px)": {
        width: "45%"
      }
    },
    dateNameContainer: {
      "@media (max-width: 600px)": {
        width: "90%"
      },
      display: "flex",
      width: "60%",
      height: "84px",
      marginBottom: "20px"
    },
    sizeDataName: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%"
      // backgroundColor:"red",
    },
    firmaContainer: {
      "@media (max-width: 600px)": {
        width: "90%"
      },
      display: "flex",
      justifyContent: "center",
      width: "60%",
      height: "240px",
      backgroundColor: "#DCDCDC"
    },
    popup: {
      "@media (max-width: 600px)": {
        width: "180px",
        height: "88px"
      },
      width: "300px",
      height: "90px",
      position: "absolute",
      background: "#F6F6F6",
      borderRadius: "10px",
      zIndex: "9",
      transition: "width .1s, height .6s",
      top: 0,
      border: "1px solid #C7C7C7"
    },
    optionsCloseDrop: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      height: "40%",
      // backgroundColor: "cyan",
      zIndex: "9"
    },
    inputPopup: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "60%",
      // backgroundColor: "pink",
      zIndex: "9",
      alignItems: "flex-start"
    },
    inputComponent: {
      width: "90%",
      height: "20%"
    },
    buttonNextArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100px",
      marginTop: "10px"
    },
    buttonNext: {
      width: "170px",
      height: "50px",
      backgroundColor: "#3BC649",
      fontFamily: "poppins",
      borderRadius: "10px",
      border: "none",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      "@media (min-width: 600px)": {
        marginBottom: "6px"
      },
      "&:hover": {
        backgroundColor: "#3DD34C",
        cursor: "pointer"
      }
    },
    addPhotoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "75px",
      width: "80%"
    },
    addAreaButton: {
      height: "55px",
      width: "220px"
    },
    PhotoContain: {
      height: "220px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media (min-width: 1200px)": {
        height: "300px"
      }
    },
    PhotoArea: {
      position: "relative",
      height: "90%",
      width: "90%",
      boxShadow:
        "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)",
      borderRadius: "5px"
    },
    PhotoStyles: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "5px"
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// Notification Styles                                                     ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const NotificationStyles = () => {
  const styles = makeStyles((theme) => ({
    allScreen: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      height: "100%",
      position: "absolute"
    },
    notificationContainer: {
      display: "flex",
      alignItems: "flex-end",
      width: "20%",
      height: "100%"
      // backgroundColor:"red",
    },
    itemContainer: {
      display: "flex",
      flexDirection: "column-reverse",
      // backgroundColor:"green",
      width: "100%",
      height: "60%"
    },
    item: {
      marginBottom: "10px",
      width: "100%",
      height: "50px"
      // backgroundColor:"blue",
    },
    itemWarning: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      backgroundColor: "#e71633",
      opacity: "0.7"
    },
    itemSuccesful: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      backgroundColor: "#008f39",
      opacity: "0.7"
    },
    itemInformation: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      backgroundColor: "#fcd659",
      opacity: "0.7"
    },
    itemText: {
      opacity: "1",
      marginLeft: "20px",
      color: "#000000"
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// ORDEN DE SERVICIO                                                       ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const OrdenServicioStyles = () => {
  const styles = makeStyles((theme) => ({
    top: {
      display: "flex",
      alignItems: "center",
      height: "50px",
      width: "100%"
      // backgroundColor: "violet",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "45px",
      width: "100%",
      backgroundColor: "#157CC1",
      borderRadius: "9px",
      color: "#FFFFFF",
      fontWeight: "500"
    },
    tableContainer: {
      width: "95%",
      height: "100%",
      marginTop: "70px",
      // paddingTop: "40px",
      "@media (max-width: 900px)": {
        marginTop: "50px"
      }
    },
    filterContainer: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      display: "flex",
      padding: "10px",
      marginBottom: "10px",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      justifyContent: "space-between"
    },
    textFilter: {
      width: "100%",
      height: "41px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    font: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "9.5px",
      letterSpacing: "7%",
      color: "#AAAAAA"
    },
    loadingArea: {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0px 30px 0px"
    },
    navStyle: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none"
    },
    inhabilitArea: {
      "@media (max-width: 600px)": {
        display: "block",
        height: "120px",
        width: "100%"
      },
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "55px",
      backgroundColor: "white",
      marginBottom: "10px",
      borderRadius: "10px"
    },
    deshabilitArea: {
      "@media (max-width: 600px)": {
        height: "35%",
        width: "100%"
      },
      "@media (min-width: 600px)": {
        width: "30%"
      },
      "@media (min-width: 900px)": {
        height: "50%",
        width: "20%"
      },
      display: "flex",
      alignItems: "center"
    },
    dateArea: {
      "@media (max-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "65%"
      },
      "@media (min-width: 600px)": {
        width: "70%",
        height: "100%"
      },
      "@media (min-width: 900px)": {
        height: "100%",
        width: "50%"
      },
      display: "flex",
      alignItems: "center"
    },
    firstDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      }
    },
    endDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      },
      padding: "0px 10px 0px 10px"
    },
    dateTitleArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        height: "100%",
        width: "40%",
        paddingBottom: "0px"
      },
      "@media (max-width: 600px)": {
        display: "flex",
        alignItems: "flex-end",
        height: "35%",
        fontSize: "12px",
        width: "50%",
        paddingBottom: "5px"
        // backgroundColor:"red",
      },
      lineHeight: "13px",
      fontWeight: "500",
      color: "#AAAAAA"
    },
    dateTimeArea: {
      height: "65%",
      width: "100%"
    },
    dateDesing: {
      width: "90%",
      height: "80%",
      borderRadius: "10px",
      backgroundColor: "#F2F2F2",
      border: "none",
      padding: "0 5px 0 5px",
      fontFamily: "poppins",
      fontSize: "14px",
      fontWeight: "medium",
      color: "#949494",
      "&::webkit-datetime-edit-month-field": {
        color: "blue"
      },
      "@media (min-width: 600px)": {
        minHeight: "100%"
      }
    },
    odin: {
      width: "100%",
      overflowY: "auto"
    },
    tableHead: {
      "& .headColor": {
        background: "#157CC1",
        color: "#F2F2F2",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px",
        alignItems: "center"
      }
    },
    tableRow: {
      "& .rowColor": {
        height: "53px",
        // background: "blue",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px",
        alignItems: "center"
      },
      "& .totalRowColor": {
        height: "53px",
        fontWeight: "bold",
        background: "#F2F2F2",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px",
        alignItems: "center"
      }
    },
    container: {
      width: "100%",
      minHeight: "100%"
      // backgroundColor:"blueviolet"
    },
    formArea: {
      "@media (max-width: 600px)": {
        width: "100%",
        minHeight: "50%"
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "30%"
      // backgroundColor:"red"
    },
    formDataContainer: {
      "@media (max-width: 600px)": {
        width: "98%",
        height: "100%"
      },
      "@media (min-width: 600px)": {
        width: "60%"
      },
      "@media (min-width: 1200px)": {
        width: "50%"
      },
      width: "40%",
      height: "50%",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      padding: "10px"
    },

    formAreaTable: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      minHeight: "330px"
    },
    buttonAreaContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      width: "100%",
      height: "60px",
      backgroundColor: "#DCDCDC"
    },
    buttonContainer: {
      "@media (max-width: 600px)": {
        width: "100%",
        marginRight: "20px"
      },
      display: "flex",
      justifyContent: "flex-end",
      height: "100%",
      width: "70%"
      // backgroundColor:"red"
    },
    buttonSave: {
      width: "170px",
      height: "50px",
      backgroundColor: "#157CC1",
      fontFamily: "poppins",
      borderRadius: "10px",
      border: "none",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      "@media (min-width: 600px)": {
        marginBottom: "6px"
      },
      "&:hover": {
        backgroundColor: "#1565c0",
        cursor: "pointer"
      }
    },
    quantity: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "40px"
      // backgroundColor: "red",
    },
    quantityContainer: {
      "@media (max-width: 600px)": {
        width: "100%"
      },
      display: "flex",
      width: "30%",
      height: "100%"
      // backgroundColor: "violet",
    },
    quantityArea: {
      "@media (max-width: 600px)": {
        width: "60%"
        // backgroundColor: "yellow",
      },
      marginRight: "10px",
      width: "60%",
      height: "100%"
    },
    moreLessArea: {
      "@media (max-width: 600px)": {
        width: "40%"
      },
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "40%",
      height: "100%"
      // backgroundColor: "green",
    },
    buttonsArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50%",
      width: "100%"
      // backgroundColor: "violet",
    },
    buttonsMoreLess: {
      backgroundColor: "#D9D9D9",
      width: "80%",
      height: "90%",
      borderRadius: "14px"
    }
  }))
  return styles
}
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// ORDEN DE COMPRA                                                       ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const OrdenCompraStyles = () => {
  const styles = makeStyles((theme) => ({
    tableContainer: {
      width: "95%",
      height: "100%",
      marginTop: "70px",
      // paddingTop: "40px",
      "@media (max-width: 900px)": {
        marginTop: "50px"
      }
    },
    filterContainer: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      display: "flex",
      padding: "10px",
      marginBottom: "10px",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      justifyContent: "space-between"
    },
    textFilter: {
      width: "100%",
      height: "41px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    font: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "9.5px",
      letterSpacing: "7%",
      color: "#AAAAAA"
    },
    loadingArea: {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0px 30px 0px"
    },
    navStyle: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none"
    },
    inhabilitArea: {
      "@media (max-width: 600px)": {
        display: "block",
        height: "120px",
        width: "100%"
      },
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "55px",
      backgroundColor: "white",
      marginBottom: "10px",
      borderRadius: "10px"
    },
    deshabilitArea: {
      "@media (max-width: 600px)": {
        height: "35%",
        width: "100%"
      },
      "@media (min-width: 600px)": {
        width: "30%"
      },
      "@media (min-width: 900px)": {
        height: "50%",
        width: "20%"
      },
      display: "flex",
      alignItems: "center"
    },
    dateArea: {
      "@media (max-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "65%"
      },
      "@media (min-width: 600px)": {
        width: "70%",
        height: "100%"
      },
      "@media (min-width: 900px)": {
        height: "100%",
        width: "50%"
      },
      display: "flex",
      alignItems: "center"
    },
    firstDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      }
    },
    endDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      },
      padding: "0px 10px 0px 10px"
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "45px",
      width: "100%",
      backgroundColor: "#157CC1",
      borderRadius: "9px",
      color: "#FFFFFF",
      fontWeight: "500"
    },
    dateTitleArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        height: "100%",
        width: "40%",
        paddingBottom: "0px"
      },
      "@media (max-width: 600px)": {
        display: "flex",
        alignItems: "flex-end",
        height: "35%",
        fontSize: "12px",
        width: "50%",
        paddingBottom: "5px"
        // backgroundColor:"red",
      },
      lineHeight: "13px",
      fontWeight: "500",
      color: "#AAAAAA"
    },
    dateTimeArea: {
      height: "65%",
      width: "100%"
    },
    dateDesing: {
      width: "90%",
      height: "80%",
      borderRadius: "10px",
      backgroundColor: "#F2F2F2",
      border: "none",
      padding: "0 5px 0 5px",
      fontFamily: "poppins",
      fontSize: "14px",
      fontWeight: "medium",
      color: "#949494",
      "&::webkit-datetime-edit-month-field": {
        color: "blue"
      },
      "@media (min-width: 600px)": {
        minHeight: "100%"
      }
    },
    odin: {
      width: "100%",
      overflowY: "auto"
    },
    tableHead: {
      "& .headColor": {
        background: "#157CC1",
        color: "#F2F2F2",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      }
    },
    tableRow: {
      "& .rowColor": {
        height: "53px",
        // background: "blue",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      },
      "& .totalRowColor": {
        height: "53px",
        fontWeight: "bold",
        background: "#F2F2F2",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      }
    },
    container: {
      width: "90%",
      minHeight: "100%"
      // backgroundColor:"blueviolet"
    },
    formArea: {
      "@media (max-width: 600px)": {
        width: "100%",
        minHeight: "50%"
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "30%"
      // backgroundColor:"red"
    },
    formDataContainer: {
      "@media (max-width: 600px)": {
        width: "98%",
        height: "100%"
      },
      "@media (min-width: 600px)": {
        width: "60%"
      },
      "@media (min-width: 1200px)": {
        width: "100%"
      },
      width: "40%",
      height: "50%",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      padding: "10px"
    },

    formAreaTable: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      minHeight: "330px"
    },
    buttonAreaContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      width: "100%",
      height: "60px",
      backgroundColor: "#DCDCDC"
    },
    buttonContainer: {
      "@media (max-width: 600px)": {
        width: "100%",
        marginRight: "20px"
      },
      display: "flex",
      justifyContent: "flex-end",
      height: "100%",
      width: "70%"
      // backgroundColor:"red"
    },
    buttonSave: {
      width: "170px",
      height: "50px",
      backgroundColor: "#157CC1",
      fontFamily: "poppins",
      borderRadius: "10px",
      border: "none",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      "@media (min-width: 600px)": {
        marginBottom: "6px"
      },
      "&:hover": {
        backgroundColor: "#1565c0",
        cursor: "pointer"
      }
    },
    quantity: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "40px"
      // backgroundColor: "red",
    },
    quantityContainer: {
      "@media (max-width: 600px)": {
        width: "100%"
      },
      display: "flex",
      width: "30%",
      height: "100%"
      // backgroundColor: "violet",
    },
    quantityArea: {
      "@media (max-width: 600px)": {
        width: "60%"
        // backgroundColor: "yellow",
      },
      marginRight: "10px",
      width: "60%",
      height: "100%"
    },
    moreLessArea: {
      "@media (max-width: 600px)": {
        width: "40%"
      },
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "40%",
      height: "100%"
      // backgroundColor: "green",
    },
    buttonsArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50%",
      width: "100%"
      // backgroundColor: "violet",
    },
    buttonsMoreLess: {
      backgroundColor: "#D9D9D9",
      width: "80%",
      height: "90%",
      borderRadius: "14px"
    }
  }))
  return styles
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// ORDEN DE SERVICIO                                                       ||
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const OtroTallerStyles = () => {
  const styles = makeStyles((theme) => ({
    top: {
      display: "flex",
      alignItems: "center",
      height: "50px",
      width: "70%"
      // backgroundColor: "violet",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "45px",
      width: "100%",
      backgroundColor: "#157CC1",
      borderRadius: "9px",
      color: "#FFFFFF",
      fontWeight: "500"
    },
    tableContainer: {
      width: "95%",
      height: "100%",
      marginTop: "70px",
      // paddingTop: "40px",
      "@media (max-width: 900px)": {
        marginTop: "50px"
      }
    },
    filterContainer: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      display: "flex",
      padding: "10px",
      marginBottom: "10px",
      borderRadius: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      justifyContent: "space-between"
    },
    textFilter: {
      width: "100%",
      height: "41px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    font: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "9.5px",
      letterSpacing: "7%",
      color: "#AAAAAA"
    },
    loadingArea: {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0px 30px 0px"
    },
    navStyle: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none"
    },
    inhabilitArea: {
      "@media (max-width: 600px)": {
        display: "block",
        height: "120px",
        width: "100%"
      },
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "55px",
      backgroundColor: "white",
      marginBottom: "10px",
      borderRadius: "10px"
    },
    deshabilitArea: {
      "@media (max-width: 600px)": {
        height: "35%",
        width: "100%"
      },
      "@media (min-width: 600px)": {
        width: "30%"
      },
      "@media (min-width: 900px)": {
        height: "50%",
        width: "20%"
      },
      display: "flex",
      alignItems: "center"
    },
    dateArea: {
      "@media (max-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "65%"
      },
      "@media (min-width: 600px)": {
        width: "70%",
        height: "100%"
      },
      "@media (min-width: 900px)": {
        height: "100%",
        width: "50%"
      },
      display: "flex",
      alignItems: "center"
    },
    firstDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      }
    },
    endDateArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        height: "100%"
      },
      "@media (max-width: 600px)": {
        display: "block",
        width: "45%",
        height: "100%"
      },
      padding: "0px 10px 0px 10px"
    },
    dateTitleArea: {
      "@media (min-width: 600px)": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        height: "100%",
        width: "40%",
        paddingBottom: "0px"
      },
      "@media (max-width: 600px)": {
        display: "flex",
        alignItems: "flex-end",
        height: "35%",
        fontSize: "12px",
        width: "50%",
        paddingBottom: "5px"
        // backgroundColor:"red",
      },
      lineHeight: "13px",
      fontWeight: "500",
      color: "#AAAAAA"
    },
    dateTimeArea: {
      height: "65%",
      width: "100%"
    },
    dateDesing: {
      width: "90%",
      height: "80%",
      borderRadius: "10px",
      backgroundColor: "#F2F2F2",
      border: "none",
      padding: "0 5px 0 5px",
      fontFamily: "poppins",
      fontSize: "14px",
      fontWeight: "medium",
      color: "#949494",
      "&::webkit-datetime-edit-month-field": {
        color: "blue"
      },
      "@media (min-width: 600px)": {
        minHeight: "100%"
      }
    },
    odin: {
      width: "100%",
      overflowY: "auto"
    },
    tableHead: {
      "& .headColor": {
        background: "#157CC1",
        color: "#F2F2F2",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      }
    },
    manodeobra: {
      fontFamily: "Poppins",
      padding: "10px",
      marginTop: "0px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box"
    },
    tableRow: {
      "& .rowColor": {
        height: "53px",
        // background: "blue",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      },
      "& .totalRowColor": {
        height: "53px",
        fontWeight: "bold",
        background: "#F2F2F2",
        color: "#717171",
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: "12px"
      }
    },
    container: {
      width: "100%",
      minHeight: "100%"
      // backgroundColor:"blueviolet"
    },

    formArea: {
      "@media (max-width: 600px)": {
        width: "100%",
        minHeight: "50%"
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "30%"
      // backgroundColor:"red"
    },
    formDataContainer: {
      "@media (max-width: 600px)": {
        width: "98%",
        height: "100%"
      },
      "@media (min-width: 600px)": {
        width: "60%"
      },
      "@media (min-width: 1200px)": {
        width: "50%"
      },
      width: "40%",
      height: "50%",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      padding: "10px"
    },

    formAreaTable: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      minHeight: "330px"
    },
    buttonAreaContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      width: "100%",
      height: "60px",
      backgroundColor: "#DCDCDC"
    },
    buttonContainer: {
      "@media (max-width: 600px)": {
        width: "100%",
        marginRight: "20px"
      },
      display: "flex",
      justifyContent: "flex-end",
      height: "100%",
      width: "70%"
      // backgroundColor:"red"
    },
    buttonSave: {
      width: "170px",
      height: "50px",
      backgroundColor: "#157CC1",
      fontFamily: "poppins",
      borderRadius: "10px",
      border: "none",
      fontWeight: "500",
      fontSize: "14px",
      letterSpacing: "0.07em",
      color: "#FFFFFF",
      marginLeft: "2px",
      "@media (min-width: 600px)": {
        marginBottom: "6px"
      },
      "&:hover": {
        backgroundColor: "#1565c0",
        cursor: "pointer"
      }
    },
    quantity: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "40px"
      // backgroundColor: "red",
    },
    quantityContainer: {
      "@media (max-width: 600px)": {
        width: "100%"
      },
      display: "flex",
      width: "30%",
      height: "100%"
      // backgroundColor: "violet",
    },
    quantityArea: {
      "@media (max-width: 600px)": {
        width: "60%"
        // backgroundColor: "yellow",
      },
      marginRight: "10px",
      width: "60%",
      height: "100%"
    },
    moreLessArea: {
      "@media (max-width: 600px)": {
        width: "40%"
      },
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "40%",
      height: "100%"
      // backgroundColor: "green",
    },
    buttonsArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50%",
      width: "100%"
      // backgroundColor: "violet",
    },
    buttonsMoreLess: {
      backgroundColor: "#D9D9D9",
      width: "80%",
      height: "90%",
      borderRadius: "14px"
    }
  }))
  return styles
}

export {
  AvatarStyles,
  ContactosStyles,
  PublicHeaderStyles,
  PublicFooterStyles,
  PrivateHeaderStyles,
  PrivateFooterStyles,
  ButtonStyles,
  InputStyles,
  UsuariosStyle,
  VistaStyles,
  VistaPrivadaStyles,
  InputMessageStyles,
  MessageStyles,
  ButtonMenuStyles,
  ModalStyles,
  EstilosInput,
  EstilosButton,
  SlideBarStyles,
  InventarioStyles,
  SelectStyles,
  productDetails,
  ChecklistStyles,
  NotificationStyles,
  HomeStyles,
  OrdenServicioStyles,
  OrdenCompraStyles,
  dropzoneStyle,
  buttonStyleToT,
  OtroTallerStyles
}
